<template>
  <Modal
    :title="$t('feedback.title')"
    sheetbelowsm
    close-button
    :primary-button="$t('submit')"
    :primary-button-loading="loading"
    :secondary-button="$t('cancel')"
    @primary="onFeedbackSubmit"
    @secondary="close"
    @close="close"
  >
    <div class="max-w-700 flex flex-col justify-between h-full">
      <div class="pt-3 px-10">
        <div class="py-4 text-center">{{ $t('feedback.message') }}</div>
      </div>
      <div class="pb-7 px-10">
        <div class="flex flex-col justify-start pr-2 w-full">
          <label class="block text-12 mb-1">{{ $t('message') }}</label>
          <Textarea v-model="feedbackMessage" class="min-h-100" />
        </div>
        <div class="mt-2" v-if="!sentiment">
          <Checkbox v-model="additionalFields.harmful" class="mt-1" :label="$t('feedback.harmful')" />
          <Checkbox v-model="additionalFields.not_true" class="mt-1" :label="$t('feedback.not_true')" />
          <Checkbox v-model="additionalFields.not_helpful" class="mt-1" :label="$t('feedback.not_helpful')" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FeedbackModal',
  props: {
    item: {
      type: Object,
    },
    sentiment: Boolean,
  },
  data() {
    return {
      feedbackMessage: '',
      loading: false,
      additionalFields: {
        harmful: false,
        not_true: false,
        not_helpful: false,
      },
    };
  },
  methods: {
    ...mapActions(['sendFeedback']),
    async onFeedbackSubmit() {
      this.loading = true;

      const fields = [];
      Object.keys(this.additionalFields).forEach((field) => {
        if (this.additionalFields[field]) {
          fields.push(field);
        }
      });
      await this.sendFeedback({ sentiment: this.sentiment, fields: fields.join(','), data: this.item, phrase: this.item?.phrase, message: this.feedbackMessage });
      this.loading = false;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
