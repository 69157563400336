<template>
  <div class="rounded-10 shadow-card px-4 flex flex-row items-center w-full flex-col px-4 pt-1 pb-1">
    <div class="w-full py-2 cursor-pointer" @click.stop="$emit('input', !value)">
      <slot name="header" />
    </div>
    <div class="w-full mx-4 overflow-hidden relative transition-all duration-300 ease-in-out max-h-0" :class="{ 'max-h-500': value, 'opacity-0': !value, 'opacity-100': value }">
      <div class="w-full transition-all duration-300 ease-in-out" :style="{ transform: value ? 'translateY(0)' : 'translateY(-100%)' }">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseMenu',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
    },
  },
};
</script>

<style scoped></style>
