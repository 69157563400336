<template>
  <div class="grid grid-flow-col auto-cols-fr z-0">
    <button
      v-for="(item, key) in items"
      :key="item"
      @click.stop="$emit('input', item)"
      class="box-border transition duration-300 ease-in-out text-primary font-700 text-center border border-gray-200 focus:outline-none"
      :class="{
        'rounded-tl-full': key === 0,
        'rounded-bl-full': key === 0,
        'rounded-tr-full': key + 1 === items.length,
        'rounded-br-full': key + 1 === items.length,
        'border-l-0': key !== 0,
        'border-r-0': key + 1 !== items.length,
        'bg-purple-200': value === item,
        'z-1': value === item,
        [height]: true,
        [padding]: true,
        [fontSize]: true,
      }"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonToggle',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    height() {
      const sizeMap = {
        small: 'h-6',
        medium: 'h-10',
        large: 'h-14',
      };
      return sizeMap[this.size] || 'h-5';
    },
    padding() {
      const sizeMap = {
        small: 'px-4',
        medium: 'px-8',
        large: 'px-12',
      };
      return sizeMap[this.size] || 'px-2';
    },
    fontSize() {
      const sizeMap = {
        small: 'text-11',
        medium: 'text-13',
        large: 'text-13',
      };
      return sizeMap[this.size] || 'text-13';
    },
  },
};
</script>
