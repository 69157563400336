<template>
  <label :class="{ 'text-alert': !!error, 'text-gray-600': !error }" class="text-12 mb-1">{{ label }}</label>
</template>

<script>
export default {
  name: 'FormLabel',
  props: {
    label: String,
    error: Boolean,
  },
};
</script>
