<template>
  <div class="bg-pink-100 p-4 rounded-12 shadow-card flex flex-wrap items-center">
    <div class="bg-red-300 rounded-full px-3 py-1 text-14 text-red-800">{{ chipText }}</div>
    <div class="flex-grow px-4"><slot /></div>
    <div>
      <PillButton primary :text="actionButtonText" @click="$router.push('/user/subscriptions')"></PillButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    chipText: {
      type: String,
      default: 'Expired Subscription',
    },
    actionButtonText: {
      type: String,
      default: 'Upgrade subscription',
    },
  },
};
</script>

<style scoped></style>
