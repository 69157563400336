import { createApp } from 'vue';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import FloatingVue from 'floating-vue';
import { createVfm } from 'vue-final-modal';
import VueApexCharts from 'vue3-apexcharts';
import vSelect from '@/components/ui/VSelectWrapper';
import 'vue-select/dist/vue-select.css';
import 'floating-vue/dist/style.css';
import App from '@/components/App.vue';
import store from '@/plugins/store';
import router from '@/plugins/router';
import modalPlugin from '@/plugins/modal';
import '@/assets/css/tailwind.css';
import '@/assets/css/vue-select.css';
import '@/assets/css/tooltip.css';
import i18n from '@/plugins/i18n';
import PaddleController from '@/helpers/paddle';
import DefaultLayout from '@/components/layouts/Default';
import OutsideClickDirective from '@/directives/outsideClickDirective';
import DefaultNoHeader from '@/components/layouts/DefaultNoHeader';
import LinkButton from '@/components/ui/LinkButton';
import Button from '@/components/ui/Button';
import PillButton from '@/components/ui/PillButton';
import IconToggleButton from '@/components/ui/IconToggleButton';
import Badge from '@/components/ui/Badge';
import Modal from '@/components/ui/Modal';
import FullScreenModal from '@/components/ui/FullScreenModal';
import Avatar from '@/components/ui/Avatar';
import Input from '@/components/ui/Input';
import FormInput from '@/components/ui/FormInput';
import FormTextarea from '@/components/ui/FormTextarea';
import FormPassword from '@/components/ui/FormPassword';
import FormSelect from '@/components/ui/FormSelect';
import Textarea from '@/components/ui/Textarea';
import Toast from '@/components/ui/Toast';
import Icon from '@/components/ui/Icon';
import Card from '@/components/ui/Card';
import MultiSelect from '@/components/ui/MultiSelect';
import Divider from '@/components/ui/Divider';
import ListCard from '@/components/ui/ListCard';
import Spinner from '@/components/ui/Spinner';
import RadioButton from '@/components/ui/RadioButton';
import Checkbox from '@/components/ui/Checkbox';
import ButtonToggle from '@/components/ui/ButtonToggle';
import CollapseMenu from '@/components/ui/CollapseMenu';
import CollapseMenuBorderless from '@/components/ui/CollapseMenuBorderless';
import CollapseCard from '@/components/ui/CollapseCard';
import ContentCard from '@/components/ui/ContentCard';
import EvaluateItem from '@/components/flow/evaluate/EvaluateItem';
import VariableChip from '@/components/ui/VariableChip';
import VariableSelect from '@/components/ui/VariableSelect';
import Toggle from '@/components/ui/Toggle';

import { setStore } from '@/helpers/api';
import CompactAnalysisCard from '@/components/conversation/CompactAnalysisCard';
import IconButton from '@/components/ui/IconButton';
import DataTable from '@/components/ui/DataTable';

setStore(store);
router.setStore(store);

const vfm = createVfm();

dayjs.extend(isToday);

// TODO fix this
// Vue.config.ignoredElements = ['g', 'r'];

const app = createApp(App);
app.use(store).use(router).use(i18n).use(vfm).mount('#app');
app.use(modalPlugin, { appInstance: app });

app.directive('outside-click', OutsideClickDirective);

app.component('v-select', vSelect);
app.component('apexchart', window.VueApexCharts);
app.component('DefaultLayout', DefaultLayout);
app.component('DefaultNoHeaderLayout', DefaultNoHeader);
app.component('PillButton', PillButton);
app.component('IconToggleButton', IconToggleButton);
app.component('Badge', Badge);
app.component('Avatar', Avatar);
app.component('Input', Input);
app.component('FormInput', FormInput);
app.component('FormTextarea', FormTextarea);
app.component('FormPassword', FormPassword);
app.component('FormSelect', FormSelect);
app.component('Textarea', Textarea);
app.component('Button', Button);
app.component('ButtonToggle', ButtonToggle);
app.component('MultiSelect', MultiSelect);
app.component('LinkButton', LinkButton);
app.component('Modal', Modal);
app.component('FullScreenModal', FullScreenModal);
app.component('Toast', Toast);
app.component('Icon', Icon);
app.component('IconButton', IconButton);
app.component('Card', Card);
app.component('Divider', Divider);
app.component('ListCard', ListCard);
app.component('Spinner', Spinner);
app.component('RadioButton', RadioButton);
app.component('Checkbox', Checkbox);
app.component('CollapseMenu', CollapseMenu);
app.component('CollapseMenuBorderless', CollapseMenuBorderless);
app.component('CollapseCard', CollapseCard);
app.component('ContentCard', ContentCard);
app.component('CompactAnalysisCard', CompactAnalysisCard);
app.component('EvaluateItem', EvaluateItem);
app.component('VariableChip', VariableChip);
app.component('VariableSelect', VariableSelect);
app.component('DataTable', DataTable);
app.component('Toggle', Toggle);

app.use(FloatingVue);
app.use(VueApexCharts);

PaddleController.setup();
