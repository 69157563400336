export const shuffle = (collection) => {
  const pick = [...collection];
  const rand = () => Math.floor(Math.random() * pick.length);
  return collection.map(() => {
    const i = rand();
    const item = pick[i];
    pick.splice(i, 1);
    return item;
  });
};

export default {
  shuffle,
};
