<template>
  <div v-if="!$slots['default']" class="h-px border border-b-0 border-gray-200 w-full" :class="{ 'my-3': !dense }"></div>
  <div v-else class="w-full grid grid-cols-1 divide-y divide-gray-300"><slot /></div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
