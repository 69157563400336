<template>
  <Modal
    sheetbelowsm
    :title="title"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-disabled="!isIntentNameValid || !dataType || loading"
    :primary-button-loading="loading"
    close-button
    @primary="onSaveClicked"
    @secondary="onCancelClicked"
    @close="onCancelClicked"
  >
    <div class="sm:w-600 px-10">
      <div class="w-full flex flex-col justify-start mt-5">
        <label class="block text-13 mb-1">{{ $t('projects.variables.name') }}</label>
        <Input ref="name" v-model="name" class="w-full" :readonly="mode === 'Edit'" />
        <p v-if="nameError.length" class="text-red-600 text-14 text-left pt-2 pl-5 italic">{{ nameError }}</p>
      </div>
      <div class="w-full flex flex-col justify-start mt-5">
        <label class="block text-13 mb-1">{{ $t('projects.variables.data_type') }}</label>
        <v-select v-model="dataType" :options="dataTypes" appendToBody :reduce="(option) => option.value" :clearable="false" />
      </div>
      <div v-if="errors.length" class="pt-3 pb-7 text-red-500">
        <ul class="text-13 list-disc pl-4">
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiPostProjectVariable, apiPutProjectVariable } from '@/helpers/api';

export default {
  name: 'CreateProjectVariable',
  props: {
    variableName: String,
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mode: 'Add',
      originalName: '',
      name: '',
      nameError: '',
      dataType: '',
      errors: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    title() {
      return this.mode === 'Add' ? this.$t('projects.variables.add') : this.$t('projects.variables.edit');
    },
    entities() {
      return Object.keys(this.projectData[this.projectId].entities);
    },
    variables() {
      return Object.keys(this.projectData[this.projectId].variables);
    },
    dataTypes() {
      let entities = this.entities.map((entity) => ({ value: entity, label: entity }));
      entities = [...entities].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      return entities;
    },
    isIntentNameValid() {
      if (this.name === '') return false;
      if (this.mode === 'Edit' && this.name !== this.originalName) {
        if (this.variables.includes(this.name)) {
          return false;
        }
      }
      if (this.mode === 'Add') {
        if (this.variables.includes(this.name)) {
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    this.$refs.name.$el.focus();
  },
  methods: {
    ...mapActions(['showToastMessage']),
    validate() {
      this.errors = [];

      if (!this.isIntentNameValid) {
        this.errors.push('The variable name is invalid!');
      }

      return !this.errors.length;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      if (this.validate()) {
        this.loading = true;
        if (this.mode === 'Add') {
          const response = await apiPostProjectVariable({
            name: this.name,
            project_id: this.projectId,
            dataType: this.dataType,
          });
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('projects.variables.added_successfully'), type: 'success' });
            this.$emit('save', response.data);
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('projects.variables.failed_to_add'), type: 'error' });
          return;
        }
        if (this.mode === 'Edit') {
          const response = await apiPutProjectVariable({
            name: this.name,
            project_id: this.projectId,
            dataType: this.dataType,
          });
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('projects.variables.updated_successfully'), type: 'success' });
            this.$emit('save', response.data);
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('projects.variables.failed_to_update'), type: 'error' });
        }
        this.loading = false;
      }
    },
  },
  created() {
    if (this.variableName && this.variableName.length) {
      this.mode = 'Edit';
      this.originalName = this.variableName;
      this.name = this.variableName;
      this.dataType = this.projectData?.[this.projectId]?.variables?.[this.variableName]?.dataType;
    } else {
      this.mode = 'Add';
      this.originalName = '';
      this.name = '';
    }
  },
};
</script>
