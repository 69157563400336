<template>
  <DefaultLayout>
    <div class="px-4 mx-auto">
      <HelpItem :id="$route.params.id" />
    </div>
  </DefaultLayout>
</template>

<script>
import HelpItem from '@/components/help/HelpItem.vue';

export default {
  name: 'Help',
  components: { HelpItem },
};
</script>
