<template>
  <div class="accordion box" role="presentation">
    <slot />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import mitt from 'mitt';

const eventbus = mitt();

export default {
  name: 'Accordion',
  props: {
    modelValue: {
      type: [Number, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      groupId: null,
      reformatData: [],
      activeIndex: 0,
      activeIds: [],
      eventbus,
    };
  },
  mounted() {
    if (this.value !== null || this.value !== undefined) {
      if (Array.isArray(this.value)) {
        this.value.forEach((index) => {
          if (!this.activeIds.includes(index)) {
            this.activeIds.push(index);
          }
        });
      } else if (Number.isInteger(this.value)) {
        this.activeIds.push(this.value);
      }
    }
    this.eventbus.on('change', (e) => {
      const { active, index } = e;
      /* eslint-disable  no-lonely-if */
      if (this.multiple) {
        if (active) {
          this.activeIds.push(index);
        } else {
          const i = this.activeIds.indexOf(index);
          this.activeIds.splice(i, 1);
        }
        const ids = this.activeIds;
        this.$emit('update:modelValue', ids);
      } else {
        if (active) {
          this.activeIds = [index];
          this.$emit('update:modelValue', index);
        } else {
          this.activeIds = [];
          this.$emit('update:modelValue', null);
        }
      }
    });
  },
  computed: {
    value() {
      return this.modelValue;
    },
  },
  methods: {
    addIndex(index) {
      if (!this.activeIds.includes(index)) {
        this.activeIds.push(index);
      }
    },
  },
  watch: {
    value: {
      async handler() {
        if (this.value !== null || this.value !== undefined) {
          if (Array.isArray(this.value)) {
            this.$slots.default().children.forEach((slot, index) => {
              if (this.value.includes(index)) {
                this.addIndex(index);
              }
            });
          } else if (Number.isInteger(this.value)) {
            await this.$nextTick();
            this.addIndex(this.value);
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.accordion {
  padding: 0;

  div:last-child .accordion-item-details {
    border-radius: 5px;
  }
}

.accordion-item-title {
  position: relative;
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
}

.accordion-item-details {
  overflow: hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter-from,
.accordion-item-leave-to {
  height: 0 !important;
}
.accordion {
  @apply p-0;

  div:last-child .accordion-item-details {
    @apply rounded-sm;
  }
}

.accordion-item-title {
  @apply relative;
}
</style>
