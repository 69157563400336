<template>
  <div>
    <v-select
      outlined
      rounded
      :clearable="false"
      autoSelect
      :searchable="false"
      :modelValue="activeItem"
      :options="getModels"
      class="ml-2 sm:ml-0 min-w-200 flex-grow"
      :reduce="(option) => option.value"
      @update:modelValue="onChange"
    >
      <template #selected-option="{ label, value }">
        <div class="flex items-center">
          <img v-if="dsLogo" :src="dsLogo" class="w-4 mr-2" alt="" />
          <span :class="{ 'opacity-0': !valueAvailable(value) }">{{ label }}</span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import VSelect from '@/components/ui/VSelectWrapper.vue';

export default {
  name: 'AdminHeader',
  components: { VSelect },
  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    ...mapState(['models', 'designTimeActiveDatasourceType', 'availableDataSources', 'designTimeActiveDatasourceModelId']),
    getModels() {
      return Object.values(this.models).map((model) => ({ label: model.name, value: model.model_id }));
    },
    dsLogo() {
      return this.availableDataSources?.[this.designTimeActiveDatasourceType]?.logo || '';
    },
  },
  methods: {
    ...mapMutations(['SET_DESIGN_TIME_ACTIVE_DATASOURCE_MODEL_ID']),
    async onChange(id) {
      this.SET_DESIGN_TIME_ACTIVE_DATASOURCE_MODEL_ID(id);
    },
    valueAvailable(modelId) {
      return this.models.some((m) => m.model_id === modelId);
    },
  },
  watch: {
    designTimeActiveDatasourceModelId: {
      handler() {
        this.activeItem = this.designTimeActiveDatasourceModelId;
      },
      immediate: true,
    },
  },
};
</script>
