<template>
  <div class="h-full border border-gray-200 border-solid flex flex-col min-h-400" :class="[containerClass]">
    <div v-if="showHeader" class="px-3 py-3 flex justify-between items-center border-gray-200 border-solid border-b">
      <template v-if="title">
        <span class="text-16" :class="[titleClasses]">{{ title }}</span>
        <div class="flex-grow"></div>
        <div>
          <slot name="header-button" />
        </div>
      </template>
      <template v-else>
        <slot name="header" />
      </template>
    </div>
    <div class="w-full flex-grow overflow-auto" :class="[paddingClasses, contentBackground, contentClass]">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentCard',
  data() {
    return {
      showHeader: true,
    };
  },
  props: {
    title: String,
    titleClasses: {
      type: String,
      default: '',
    },
    paddingClasses: {
      type: String,
      default: 'px-3 py-3',
    },
    contentBackground: {
      type: String,
      default: 'bg-gray-100',
    },
    radius: {
      type: String,
      default: '4',
    },
  },
  computed: {
    containerClass() {
      return `rounded-${this.radius}`;
    },
    contentClass() {
      return `rounded-bl-${this.radius} rounded-br-${this.radius}`;
    },
  },
  created() {
    this.showHeader = this.title || this.$slots?.header;
  },
};
</script>
