<template>
  <div :class="{ 'w-full': open, 'w-18': !open }" class="shadow-navigation flex-grow rounded-10 flex flex-col items-start relative bg-primary">
    <div class="w-full overflow-hidden flex items-center">
      <div class="w-18 h-18 flex justify-center items-center flex-shrink-0" @click="$emit('update:open', !open)">
        <img class="w-9" :src="navigationLogo" :alt="companyName" />
      </div>
      <span class="text-white font-500 text-24 capitalize">{{ companyName }}</span>
    </div>

    <div class="w-full">
      <NavigationItem
        v-for="(item, index) in navigationItems"
        :key="index"
        class="my-5"
        :text="$t(item.label)"
        :icon="item.icon"
        :iconClass="item.icon === 'logout' ? '!w-7 !h-7' : ''"
        :selected="isNavItemSelected(item)"
        @click="selectNavItem(item)"
      />
    </div>
    <div class="w-full mt-auto relative">
      <NavigationItem :iconClass="open ? 'rotate-180 transform' : ''" icon="slide_panel_open" @click="onToggle" />
      <span v-if="open" class="absolute right-4 top-7 text-gray-200">v{{ version }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavigationItem from '@/components/ui/NavigationItem';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import { FEATURES } from '@/constants';

export default {
  name: 'Navigation',
  components: { NavigationItem },
  props: {
    open: Boolean,
  },
  emits: ['update:open'],
  data() {
    return {
      navItems: [
        { label: 'navigation.dashboard', to: '/', icon: 'dashboard' },
        { label: 'navigation.conversations', to: '/conversation', icon: 'conversations', restrictTo: FEATURES.CONVERSATIONS },
        { label: 'navigation.collections', to: '/collections', icon: 'collections', restrictTo: FEATURES.COLLECTIONS },
        { label: 'navigation.projects', to: '/no-code', icon: 'code_off', restrictTo: FEATURES.PROJECTS },
        { label: 'navigation.settings', to: '/user/settings', icon: 'settings', restrictTo: FEATURES.SETTINGS },
        { label: 'navigation.admin', to: '/admin', icon: 'admin_panel_settings', restrictTo: FEATURES.ADMIN_DASHBOARD },
        { label: 'navigation.logout', to: '/logout', icon: 'logout' },
      ],
    };
  },
  computed: {
    ...mapState(['user', 'features']),
    version() {
      return process.env.VUE_APP_VERSION;
    },
    navigationItems() {
      return this.navItems.filter((item) => {
        if (item.restrictTo) {
          return this.features.includes(item.restrictTo);
        }
        return true;
      });
    },
    navigationLogo() {
      return process.env.VUE_APP_NAVIGATION_LOGO;
    },
    companyName() {
      return process.env.VUE_APP_COMPANY;
    },
  },
  methods: {
    ...mapActions(['logout']),
    selectNavItem(item) {
      if (item.to === '/logout') {
        this.$showModal(DeleteModal, {
          title: this.$t('logout'),
          subtitle: this.$t('confirm_logout_message'),
          confirm: this.$t('logout'),
          onConfirm: () => {
            this.logout({ clearIdb: true });
          },
        });
      } else {
        this.$router.push(item.to);
      }
    },
    isNavItemSelected(item) {
      if (item.to.startsWith('/admin')) {
        return this.$route.path.startsWith('/admin') || this.$route.path.startsWith('/iam') || this.$route.path.startsWith('/conversation/');
      }
      if (item.to.startsWith('/user')) {
        return this.$route.path.startsWith('/user');
      }
      if (item.to.startsWith('/no-code')) {
        return this.$route.path.startsWith('/no-code');
      }
      if (item.to.startsWith('/collection')) {
        return this.$route.path.startsWith('/collection');
      }
      return this.$route.path === item.to;
    },
    onToggle() {
      this.$emit('update:open', !this.open);
    },
  },
};
</script>
