<template>
  <div class="w-full flex flex-col-reverse justify-start">
    <div v-if="getResponses && getResponses.length" class="flex flex-col sm:pr-5">
      <div v-for="(response, index) in getResponses" :key="index" class="flex w-full flex-col items-start">
        <div v-if="response.text">
          <ConversationMessage show-icon incoming :text="response.text" />
          <FeedbackBox v-if="showFeedbackButtons && index + 1 === getResponses.length && !response.prompt && !response.card" :feedback-data="getFeedbackData" />
        </div>
        <div v-if="response.prompt">
          <ConversationPrompt :disabled="disablePrompt" :prompt="response.prompt" @item-click="$emit('prompt-item-click', $event)" />
          <FeedbackBox v-if="showFeedbackButtons && index + 1 === getResponses.length && !response.card" :feedback-data="getFeedbackData" />
        </div>
        <div v-if="response.card && Object.keys(response.card).length && !response.card.chart && !response.card.table && response.card.text" class="w-full">
          <ConversationMessage show-icon incoming :text="response.card.text" />
        </div>
        <div v-else-if="response.card && Object.keys(response.card).length" class="w-full">
          <ConversationCard :class="{ '!rounded-tl-none': Object.keys(response.card).length > 1 }" :result="response.card" :datasource-type="activeDatasourceType"></ConversationCard>
          <FeedbackBox v-if="showFeedbackButtons && index + 1 === getResponses.length" :feedback-data="getFeedbackData" />
        </div>
      </div>
    </div>

    <ConversationMessage v-if="item.loading || item.error" show-icon :incoming="item.loading || !!item.error" :error="!!item.error" :avatar="user.picture">
      <div class="flex flex-row items-center" v-if="item.loading">
        <Icon name="loading_dots" class="w-10 text-primary" />
      </div>
      <div v-if="!item.loading && item.error" class="flex items-center">
        <div>{{ errorMessage }}</div>
      </div>
    </ConversationMessage>

    <div class="pl-5 flex">
      <ConversationMessage v-if="item.query.phrase" :avatar="user.picture" :text="item.query.phrase" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConversationMessage from '@/components/conversation/ConversationMessage';
import ConversationPrompt from '@/components/conversation/ConversationPrompt';
import ConversationCard from '@/components/conversation/ConversationCard';
import FeedbackBox from '@/components/conversation/FeedbackBox.vue';

export default {
  name: 'ConversationItem',
  components: { FeedbackBox, ConversationCard, ConversationPrompt, ConversationMessage },
  props: {
    item: Object,
    index: [Number, String],
    disablePrompt: Boolean,
    showFeedbackButtons: Boolean,
  },
  data() {
    return {
      errorTypewriterHtml: '',
    };
  },
  computed: {
    ...mapState(['user', 'activeDatasourceType']),
    getResponses() {
      return this.item?.responses || [];
    },
    getFeedbackData() {
      return { query: this.item?.query || {}, responses: this.getResponses };
    },
    insights() {
      return this.item?.queryData?.result?.insights || [];
    },
    errorMessage() {
      if (this.item?.error) {
        if (typeof this.item.error === 'object') {
          return this.item?.error?.message;
        }
        return this.item.error;
      }
      return '';
    },
  },
};
</script>
