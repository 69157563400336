<template>
  <div :id="id" class="h-2 w-full" :class="computedClass" @dragover="onDragOver" @dragenter="onDragEnter" @dragleave="onDragLeave" @drop="onDrop"></div>
</template>

<script>
import { mapMutations } from 'vuex';
import uuidv4 from '@/helpers/uuid';
import { addEvaluateItem, removeEvaluateItem } from '@/helpers/flow';

export default {
  name: 'EvaluateDropZone',
  props: {
    index: Number,
    parentId: String,
    evaluateKey: String,
    evaluate: Array,
    accept: Array,
  },
  data() {
    return {
      id: uuidv4(),
      hover: false,
    };
  },
  computed: {
    computedClass() {
      if (this.hover) {
        return { 'mt-1 mb-1 h-7 bg-indigo-100 border-dotted border-2 border-blue-300 rounded-4': this.hover };
      }
      return {};
    },
  },
  methods: {
    ...mapMutations(['SET_SELECTED_INTENT_DEFINITION_STEP']),
    onDragOver(e) {
      e.preventDefault();
      return true;
    },
    onDragEnter() {
      this.hover = true;
    },
    onDragLeave() {
      this.hover = false;
    },
    onDrop(e) {
      e.preventDefault();
      const dragEvaluateId = e.dataTransfer.getData('id');
      const dragEvaluateItem = JSON.parse(e.dataTransfer.getData('item'));
      dragEvaluateItem.id = uuidv4();
      const type = e.dataTransfer.getData('type');
      this.hover = false;

      if (this.accept && Array.isArray(this.accept) && type && !this.accept.includes(type)) {
        return;
      }

      const evaluateCopy = JSON.parse(JSON.stringify(this.evaluate));

      const newId = addEvaluateItem(evaluateCopy, this.parentId, this.index, dragEvaluateItem, this.evaluateKey);
      removeEvaluateItem(evaluateCopy, dragEvaluateId);

      this.$emit('evaluate-update', evaluateCopy, newId, e);
    },
  },
};
</script>
