<template>
  <div class="flex w-full h-35 bg-secondary rounded-10">
    <div class="px-8 py-4">
      <div class="text-off-white text-18 font-500">Do you still need our help?</div>
      <div class="text-off-white tracking-wide">Send your request via email</div>
      <PillButton text="Contact Us" class="!px-8 !text-[#655E8E] !h-10 mt-3" @click="$emit('contact-us')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpMore',
};
</script>

<style scoped lang="scss"></style>
