<template>
  <Modal :title="titleText" close-button container-class="sm:min-w-600" @close="onCancel">
    <div>
      <div class="flex flex-col items-start justify-start px-10 py-10">
        <div v-html="subtitle"></div>
        <template v-if="info">
          <br />
          <div v-html="info"></div>
        </template>
      </div>
      <Divider />
      <div class="flex flex-row-reverse mb-4 w-full justify-between px-7">
        <PillButton :text="confirmText" :error="type === 'error'" :loading="loading" :disabled="loading" :primary="type === 'primary'" @click="onConfirmClick" />
        <PillButton :text="cancelText" :disabled="loading" @click="onCancel" />
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    info: {
      type: String,
      default: null,
    },
    confirm: {
      type: String,
    },
    cancel: {
      type: String,
    },
    type: {
      type: String,
      default: 'error',
    },
    onConfirm: {
      type: Function,
    },
  },
  emits: ['confirm', 'cancel', 'close'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    titleText() {
      return this.title || this.$t('confirm');
    },
    confirmText() {
      return this.confirm || this.$t('confirm');
    },
    cancelText() {
      return this.cancel || this.$t('cancel');
    },
  },
  methods: {
    async onConfirmClick() {
      if (this.onConfirm && typeof this.onConfirm === 'function') {
        this.loading = true;
        try {
          await this.onConfirm();
          this.loading = false;
          this.$emit('close', this);
        } catch {
          this.loading = false;
        }
      } else {
        this.$emit('confirm', this);
      }
    },
    onCancel() {
      if (this.loading) return;
      this.$emit('cancel', this);
      this.close();
    },
    close() {
      // this.$modal?.close();
      this.loading = false;
    },
  },
};
</script>
