<template>
  <div
    class="rounded-full flex items-center justify-center select-none"
    :class="{ 'p-1': dense, 'p-2': !dense, 'bg-primary': primary, 'bg-tertiary text-white hover:opacity-90': tertiary, 'text-white': primary, 'opacity-50': disabled, 'cursor-pointer': !disabled }"
    @click="onClick"
  >
    <Icon :class="{ [`w-${size}`]: true, [`h-${size}`]: true }" :name="icon" />
  </div>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: String,
    size: {
      type: [String, Number],
      default: 5,
    },
    disabled: Boolean,
    primary: Boolean,
    tertiary: Boolean,
    dense: Boolean,
  },
  emits: ['click'],
  methods: {
    onClick($event) {
      if (!this.disabled) {
        this.$emit('click', $event);
      }
    },
  },
};
</script>
