<template>
  <div class="min-h-full max-w-screen-xxl w-full sm:pt-10 pb-5 max-md:px-3">
    <div class="font-600 text-20 md:text-24 mb-10">{{ $t('onboarding.message_4') }}</div>

    <template v-if="accountsLoading">
      <div class="flex flex-col font-500 items-center justify-center w-full h-full mb-5">
        <div>{{ $t('datastore.fetching_accounts') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </template>
    <template v-else>
      <div
        v-for="(account, index) in userAccounts"
        :key="index"
        class="bg-white rounded-10 shadow-card flex flex-row border-2 border-solid p-4 items-center cursor-pointer mb-4"
        :class="{ 'border-primary': selectedAccountId === account.id, 'border-transparent': selectedAccountId !== account.id }"
        @click="selectAccount(account)"
      >
        <div class="w-11 h-11 mr-4">
          <img class="h-11" :src="availableDataSources[account.type].logo" :alt="account.name" />
        </div>
        <div class="flex flex-wrap">
          <span class="mr-4 font-500">{{ availableDataSources[account.type] && availableDataSources[account.type].name }}</span>
          <span class="mr-8 max-md:w-full">({{ account.datasourceId }})</span>
          <span>{{ account.name }}</span>
        </div>
      </div>

      <div class="mt-10 flex justify-end">
        <PillButton :disabled="saveAccountLoading" :text="$t('onboarding.previous')" class="mr-3" @click="back"></PillButton>
        <PillButton :disabled="!selectedAccountId || saveAccountLoading" tertiary class="capitalize" :text="$t('login.continue')" @click="next"></PillButton>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Account',
  data() {
    return {
      selectedAccountId: null,
      userAccounts: [],
      accountsLoading: false,
      saveAccountLoading: false,
    };
  },
  computed: {
    ...mapState(['data_sources', 'availableDataSources']),
    selectedAccount() {
      return this.userAccounts.find((acc) => acc.id === this.selectedAccountId);
    },
    selectedDatasourceType() {
      return this.$route.query.type;
    },
    getActiveDataSourceDatasourceId() {
      if (this.selectedDatasourceType) {
        return this.getDataSourceDataSourceId(this.selectedDatasourceType);
      }
      return null;
    },
    userDataSources() {
      const dataSources = [];
      Object.entries(this.data_sources).forEach(([type, item]) => {
        Object.values(item).forEach((dsItem) => {
          if (dsItem.active) {
            if (!dataSources.includes(type)) {
              dataSources.push(type);
            }
          }
        });
      });
      return dataSources;
    },
    userDataSourceCount() {
      return Object.keys(this.data_sources).length;
    },
  },
  async created() {
    await this.checkAccountAlreadyAdded();
    try {
      this.accountsLoading = true;
      const response = await this.fetchDatasourceAccounts({ type: this.selectedDatasourceType, datasource_id: this.getActiveDataSourceDatasourceId });
      if (!response) {
        this.back();
        return;
      }
      this.userAccounts = (response || []).map((acc) => {
        return {
          ...acc,
          type: this.selectedDatasourceType,
          datasourceId: this.getActiveDataSourceDatasourceId,
        };
      });
    } catch (e) {
      this.back();
    }

    this.accountsLoading = false;
  },
  methods: {
    ...mapActions(['fetchDatasourceAccounts', 'showToastMessage', 'addAccountToDatasource']),
    ...mapMutations(['SET_ACTIVE_DATASOURCE_ACCOUNT_ID', 'SET_ACTIVE_DATASOURCE_DATASOURCE_ID', 'SET_ACTIVE_DATASOURCE']),
    back() {
      this.$router.replace({ query: null });
      this.$emit('back');
    },
    async next() {
      this.saveAccountLoading = true;
      try {
        const response = await this.addAccountToDatasource({
          type: this.selectedAccount.type,
          datasource_id: this.selectedAccount.datasourceId,
          account: {
            id: this.selectedAccount.id,
            name: this.selectedAccount.name,
            type: this.selectedAccount.type,
            datasource_id: this.selectedAccount.datasourceId,
          },
          showSuccessMessage: false,
        });

        if (response) {
          const accounts = this.data_sources?.[this.selectedAccount?.type]?.[this.selectedAccount?.datasourceId]?.accounts || {};
          const account = Object.values(accounts)?.find((acc) => acc.id === this.selectedAccount.account_id) || {};
          // await this.completeOnboarding({ accountId: account.account_id, datasource_id: this.selectedAccount.datasourceId, type: this.selectedAccount.type });

          this.SET_ACTIVE_DATASOURCE_ACCOUNT_ID(account.accountId);
          this.SET_ACTIVE_DATASOURCE_DATASOURCE_ID(this.selectedAccount.datasourceId);
          this.SET_ACTIVE_DATASOURCE(this.selectedAccount.type);
          this.$emit('next');
          this.saveAccountLoading = false;
        } else {
          this.saveAccountLoading = false;
        }
      } catch (e) {
        this.saveAccountLoading = false;
      }
    },
    selectAccount(account) {
      this.selectedAccountId = account.id;
    },
    getDataSourceDataSourceId(type) {
      if (!this.userDataSourceCount) {
        return null;
      }
      if (!this.userDataSources || !this.userDataSources.includes(type)) {
        return null;
      }

      const items = [];
      Object.entries(this.data_sources[type]).forEach(([datasourceId, item]) => {
        if (item.active) {
          items.push(datasourceId);
        }
      });
      return items[0];
    },
    async checkAccountAlreadyAdded() {
      let hasAccount = false;
      let accountId;
      let datasourceId;
      let datasourceType;
      Object.values(this.data_sources[this.selectedDatasourceType]).forEach((item) => {
        if (item.active && Object.keys(item.accounts).length) {
          hasAccount = true;
          const { account_id, datasource_id, type } = Object.values(item.accounts)[0];
          accountId = account_id;
          datasourceId = datasource_id;
          datasourceType = type;
        }
      });
      if (hasAccount) {
        this.SET_ACTIVE_DATASOURCE_ACCOUNT_ID(accountId);
        this.SET_ACTIVE_DATASOURCE_DATASOURCE_ID(datasourceId);
        this.SET_ACTIVE_DATASOURCE(datasourceType);
        this.$emit('next');
      }
    },
  },
};
</script>
