<template>
  <Modal fullbelowsm container-class="w-full max-w-800">
    <div class="w-full flex pt-5 px-5 justify-between items-center">
      <div v-if="step === 2 && !checkoutCompleted" class="mr-auto rounded-full h-6 flex justify-center items-center cursor-pointer" @click.stop="back">
        <Icon name="chevron_left" class="w-5 h-5 mr-2" />
        {{ $t('back') }}
      </div>
      <div class="ml-auto rounded-full h-6 flex justify-center items-center cursor-pointer" @click.stop="close">
        <Icon name="clear" class="w-5 h-5" />
      </div>
    </div>
    <div v-if="quota < 0" class="text-center text-600 pb-10">
      <span class="text-30"> {{ $t('subscription.reached_limits') }} </span> <br />
      <span class="text-24">{{ $t('subscription.upgrade_now_for_continued_access') }}</span>
    </div>
    <div v-if="step === 1" class="w-full sm:pr-2">
      <div class="flex justify-between items-center px-10">
        <div class="mx-auto flex items-center justify-center relative pr-10">
          <span class="text-16 mx-3" :class="{ 'text-indigo-600': !periodAnnual, 'text-gray-600': periodAnnual }">{{ $t('subscription.monthly') }}</span>
          <Toggle v-model="periodAnnual" valueMode />
          <span class="text-16 mx-3" :class="{ 'text-indigo-600': periodAnnual, 'text-gray-600': !periodAnnual }">{{ $t('subscription.annual') }}</span>
          <span class="transform rotate-180">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 400 400" fill="none">
              <path d="M35 262C160.529 140.938 328.006 207.285 361 215.518" stroke="#000000" stroke-opacity="0.9" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M343.69 143C355.23 190.289 361 214.681 361 216.177C361 218.421 327.488 234.13 312 258"
                stroke="#000000"
                stroke-opacity="0.9"
                stroke-width="16"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="text-indigo-600 absolute -right-3 w-15 -top-3 text-center text-11 font-500">{{ $t('subscription.two_months_free') }}</span>
        </div>
      </div>
      <div class="bg-white h-full flex flex-wrap p-5 sm:p-10 pt-10 pb-0 rounded-[30px] max-sm:pb-8">
        <div class="w-full md:w-1/2 md:border-r border-solid border-gray-200 sm:pr-6 flex flex-col">
          <div class="flex items-center">
            <img class="h-12" src="@/assets/svg/fidsy-free.svg" />
            <div class="ml-1">
              <div class="text-3xl">Premium</div>
            </div>

            <div class="ml-auto text-right">
              <div class="text-20 font-500">{{ premiumPriceText }}</div>
              <div class="text-11 text-gray-600">{{ billedText }}</div>
            </div>
          </div>
          <div class="text-20 mt-6 font-400">{{ $t('subscription.for_individuals') }}</div>

          <ul class="list-disc py-5 pl-4 sm:pl-6 flex-grow">
            <li class="my-1">All datasources</li>
            <li class="my-1">
              Conversational assistant<br />
              - 3000 conversations per month
            </li>
            <li class="my-1">GPT Insights and Recommendations</li>
            <li class="my-1">
              Natural language dashboards<br />
              - 5 collections<br />
              - 10 cards per collection
            </li>
            <li class="my-1">Daily, weekly and monthly email digests</li>
            <li class="my-1">Alexa integration</li>
            <li class="my-1">
              Messaging App integrations <br />
              - Slack, Teams, Skype, etc.
            </li>
            <li class="my-1">Advanced reporting</li>
            <li class="my-1">Report sharing, export and more...</li>
          </ul>
          <div>
            <PillButton v-if="(isPremiumMonthly && !periodAnnual) || (isPremiumAnnual && periodAnnual)" disabled icon="check" class="w-full mx-auto" primary :text="$t('subscription.current_plan')" />
            <PillButton v-else class="w-full mx-auto" primary :text="$t('subscription.select_plan')" @click="onSelectClick('Premium')" />
          </div>
        </div>
        <div class="w-full md:w-1/2 mt-20 md:mt-0 sm:pl-6 flex flex-col">
          <div class="flex items-center">
            <img class="h-12" src="@/assets/svg/fidsy-unlimited.svg" />
            <div class="ml-1">
              <div class="text-3xl">Unlimited</div>
            </div>

            <div class="ml-auto text-right">
              <div class="text-20 font-500">{{ unlimitedPriceText }}</div>
              <div class="text-11 text-gray-600">{{ billedText }}</div>
            </div>
          </div>
          <div class="text-20 mt-6 font-400">For teams & businesses</div>

          <ul class="list-disc py-5 pl-4 sm:pl-6 flex-grow">
            <li class="my-1">All datasources</li>
            <li class="my-1">
              Conversational assistant<br />
              - Unlimited conversations
            </li>
            <li class="my-1">GPT Insights and Recommendations</li>
            <li class="my-1">
              Natural language dashboards<br />
              - 10 collections<br />
              - 20 cards per collection
            </li>
            <li class="my-1">Daily, weekly and monthly email digests</li>
            <li class="my-1">Alexa integration</li>
            <li class="my-1">
              Messaging App integrations <br />
              - Slack, Teams, Skype, etc.
            </li>
            <li class="my-1">Advanced reporting</li>
            <li class="my-1">Report sharing, export and more...</li>
          </ul>
          <div>
            <PillButton
              v-if="(isUnlimitedMonthly && !periodAnnual) || (isUnlimitedAnnual && periodAnnual)"
              disabled
              icon="check"
              class="w-full mx-auto"
              primary
              :text="$t('subscription.current_plan')"
            />
            <PillButton v-else class="w-full mx-auto" primary :text="$t('subscription.select_plan')" @click="onSelectClick('Unlimited')" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && !planId && !checkoutCompleted" class="w-full pr-2">
      <div class="bg-white h-full flex flex-wrap p-5 sm:p-10 pt-10 pb-0 sm:pb-0 rounded-[30px]">
        <div class="w-full pr-6 flex flex-col">
          <div class="flex items-center">
            <img class="h-12" src="@/assets/svg/fidsy-free.svg" />
            <div class="ml-1">
              <div class="text-3xl">{{ planTypes[selectedPlanId] }}</div>
            </div>

            <div class="ml-auto text-right">
              <div class="text-20 font-500">
                <span v-if="discountApplied" class="line-through mr-3">{{ selectedPlanPriceText }}</span> {{ checkoutTotal }}
                <span class="text-11 font-400 text-gray-600">{{ $t('subscription.inc_tax') }}</span>
              </div>
              <div v-if="!discountApplied" class="text-11 text-gray-600">{{ billedText }}</div>
              <div v-if="discountApplied" class="text-11 text-gray-600">
                <span v-if="periodAnnual">{{ $t('subscription.after_discount_yearly', { price: selectedPlanPriceText }) }}</span>
                <span v-else>{{ $t('subscription.after_discount_monthly', { price: selectedPlanPriceText }) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="paddle-checkout-container flex-grow w-full flex-shrink-0 pl-2" style="min-height: 650px"></div>
      </div>
    </div>
    <div v-if="step === 2 && planId && !checkoutCompleted" class="w-full pr-2 relative">
      <div class="bg-white h-full flex flex-wrap p-5 sm:p-10 pt-10 pb-0 sm:pb-0 rounded-[30px]">
        <div class="w-full flex flex-col">
          <div class="flex items-center">
            <img class="h-12" src="@/assets/svg/fidsy-free.svg" />
            <div class="ml-1">
              <div class="text-3xl">{{ planNames[selectedPlanId] }}</div>
            </div>

            <div class="ml-auto text-right">
              <div class="text-20 font-500">
                <span v-if="discountApplied" class="line-through mr-3">{{ selectedPlanPriceText }}</span> {{ checkoutTotal }}
                <span class="text-11 font-400 text-gray-600">{{ $t('subscription.inc_tax') }}</span>
              </div>
              <div v-if="!discountApplied" class="text-11 text-gray-600">{{ billedText }}</div>
              <div v-if="discountApplied" class="text-11 text-gray-600">
                <span v-if="periodAnnual">{{ $t('subscription.after_discount_yearly', { price: selectedPlanPriceText }) }}</span>
                <span v-else>{{ $t('subscription.after_discount_monthly', { price: selectedPlanPriceText }) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 bg-white h-full p-5 sm:p-10 pt-10 pb-0 sm:pb-0 rounded-[30px]">
        <div v-if="previewFetching" class="absolute flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0 bg-white z-1">
          <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
        </div>

        <div class="flex justify-between my-1">
          <div class="font-500">{{ $t('subscription.payment_method') }}</div>
          <div class="text-gray-700 capitalize">{{ getLastPaymentType }}</div>
        </div>
        <div class="flex justify-between my-1">
          <div class="font-500">{{ $t('subscription.charge_for', { plan: planNames[selectedPlanId] }) }}</div>
          <div class="text-gray-700">{{ upgradePrice.charge }}</div>
        </div>
        <div class="flex justify-between my-1">
          <div class="font-500">{{ $t('subscription.credit_from', { plan: planNames[planId] }) }}</div>
          <div class="text-gray-700">{{ upgradePrice.credit }}</div>
        </div>
        <div class="border-b h-1 border-gray-200 border-solid my-3"></div>
        <div class="flex justify-between my-1">
          <div>
            <div class="text-18 font-500">{{ $t('subscription.charges_today') }}</div>
            <div class="text-gray-700">{{ $t('subscription.estimated_prorated_charge') }}</div>
          </div>
          <div class="text-20 font-500">{{ upgradePrice.chargesToday }}</div>
        </div>
        <div class="border-b h-1 border-gray-200 border-solid my-3"></div>
        <div class="flex justify-between my-1">
          <div class="font-500">{{ $t('subscription.next_full_bill') }} ({{ nextBillDate }})</div>
          <div class="text-gray-700">{{ upgradePrice.nextFullBill }}</div>
        </div>
        <div class="w-full flex justify-end">
          <PillButton :text="$t('confirm')" primary :loading="updating" :disabled="updating" class="w-[140px] my-8" @click="confirmUpgrade" />
        </div>
      </div>
    </div>
    <div v-if="checkoutCompleted" class="w-full h-full mt-5 mb-2 flex flex-col items-center justify-center min-h-500">
      <Spinner class="w-6" />
      <div class="text-13 pt-2">{{ $t('subscription.subscription_processing') }}</div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaddleController from '@/helpers/paddle';
import delay from '@/helpers/delay';
import { convertPaddlePrice, formatPrice } from '@/helpers/pricing';
import dayjs from 'dayjs';

export default {
  name: 'Subscribe',
  data() {
    return {
      periodAnnual: false,
      currency: 'USD',
      selectedPlanId: null,
      checkoutTotal: 0,
      discountApplied: false,
      checkoutCompleted: false,
      previewFetching: true,
      step: 1,
      prices: {
        [PaddleController.productId.PREMIUM_MONTHLY]: null,
        [PaddleController.productId.PREMIUM_ANNUAL]: null,
        [PaddleController.productId.UNLIMITED_MONTHLY]: null,
        [PaddleController.productId.UNLIMITED_ANNUAL]: null,
      },
      planNames: {
        [PaddleController.productId.PREMIUM_MONTHLY]: 'Premium Monthly',
        [PaddleController.productId.PREMIUM_ANNUAL]: 'Premium Annual',
        [PaddleController.productId.UNLIMITED_MONTHLY]: 'Unlimited Monthly',
        [PaddleController.productId.UNLIMITED_ANNUAL]: 'Unlimited Annual',
      },
      planTypes: {
        [PaddleController.productId.PREMIUM_MONTHLY]: 'Premium',
        [PaddleController.productId.PREMIUM_ANNUAL]: 'Premium',
        [PaddleController.productId.UNLIMITED_MONTHLY]: 'Unlimited',
        [PaddleController.productId.UNLIMITED_ANNUAL]: 'Unlimited',
      },
      upgradePrice: {
        nextFullBill: 0,
        chargesToday: 0,
        credit: 0,
        charge: 0,
      },
      nextBillDate: null,
      updating: false,
    };
  },
  emits: ['close'],
  computed: {
    ...mapState(['user', 'tenantId', 'subscription', 'quota', 'payments', 'addresses']),
    userAddressId() {
      const addresses = [...this.addresses];
      const highest = addresses.filter((a) => Number.isInteger(a?.custom_data?.lastDefaultDate)).sort((a, b) => b?.custom_data?.lastDefaultDate - a?.custom_data?.lastDefaultDate)?.[0];
      return highest?.id || this.addresses?.[0]?.id || null;
    },
    getLastPaymentType() {
      const details = this.payments?.[0].payments?.[0]?.method_details || {};
      if ('type' in details && details.type === 'card') {
        return details?.card?.type || details.type;
      }
      return this.payments?.[0].payments?.[0]?.method_details?.type;
    },
    planId() {
      return this.subscription.plan === 'FREE' ? null : this.subscription.plan;
    },
    billedText() {
      return this.periodAnnual ? this.$t('subscription.billed_annual') : this.$t('subscription.billed_monthly');
    },
    selectedPlanPriceText() {
      return this.prices[this.selectedPlanId];
    },
    premiumPriceText() {
      return this.periodAnnual ? this.prices[PaddleController.productId.PREMIUM_ANNUAL] : this.prices[PaddleController.productId.PREMIUM_MONTHLY];
    },
    unlimitedPriceText() {
      return this.periodAnnual ? this.prices[PaddleController.productId.UNLIMITED_ANNUAL] : this.prices[PaddleController.productId.UNLIMITED_MONTHLY];
    },
    isPremiumMonthly() {
      return this.planId === PaddleController.productId.PREMIUM_MONTHLY;
    },
    isPremiumAnnual() {
      return this.planId === PaddleController.productId.PREMIUM_ANNUAL;
    },
    isUnlimitedMonthly() {
      return this.planId === PaddleController.productId.UNLIMITED_MONTHLY;
    },
    isUnlimitedAnnual() {
      return this.planId === PaddleController.productId.UNLIMITED_ANNUAL;
    },
  },
  created() {
    PaddleController.on('checkout.discount.applied', this.onCouponApply);
    PaddleController.on('checkout.discount.removed', this.onCouponRemove);
    PaddleController.on('checkout.loaded', this.onCheckoutLoad);
    PaddleController.on('checkout.completed', this.onCheckoutComplete);
    this.getPrices();

    if (!this.userAddressId) {
      this.fetchAddresses();
    }

    if (this.planId) {
      if (this.isUnlimitedAnnual || this.isPremiumAnnual) {
        this.periodAnnual = true;
      }
    }
  },
  methods: {
    ...mapActions(['showToastMessage', 'getTenant', 'updateSubscription', 'updatePreviewSubscription', 'fetchPayments', 'fetchAddresses']),
    back() {
      PaddleController.closeCheckout();
      this.step = 1;
      this.selectedPlanId = null;
    },
    close() {
      this.$emit('close');
    },
    getPrices() {
      PaddleController.getPrices().then((res) => {
        const { data } = res;
        this.currency = data.currencyCode;
        const items = data.details.lineItems;
        items.forEach((item) => {
          this.prices[item.price.id] = item.formattedTotals.total;
        });
      });
    },
    async confirmUpgrade() {
      this.updating = true;
      try {
        const scheduledChange = this.subscription.scheduled_change;
        const planId = this.subscription.plan;
        const res = await this.updateSubscription({ priceId: this.selectedPlanId });
        if (res) {
          await this.checkScheduledChange({ scheduledChange, planId });
          this.fetchPayments();
          this.showToastMessage({ message: this.$t('subscription.updated_successfully'), type: 'success' });
          this.updating = false;
          this.close();
        }
      } catch (e) {
        this.updating = false;
      }
    },
    async onSelectClick(plan) {
      if (this.planId) {
        this.step = 2;
        if (plan === 'Premium') {
          const planId = this.periodAnnual ? PaddleController.productId.PREMIUM_ANNUAL : PaddleController.productId.PREMIUM_MONTHLY;
          this.selectedPlanId = planId;
        }
        if (plan === 'Unlimited') {
          const planId = this.periodAnnual ? PaddleController.productId.UNLIMITED_ANNUAL : PaddleController.productId.UNLIMITED_MONTHLY;
          this.selectedPlanId = planId;
        }

        this.previewFetching = true;
        const response = await this.updatePreviewSubscription({ priceId: this.selectedPlanId });
        this.previewFetching = false;
        if (response) {
          this.checkoutTotal = this.prices[this.selectedPlanId];
          this.upgradePrice.credit = convertPaddlePrice(response.update_summary.credit.amount, response.currency_code);
          this.upgradePrice.charge = convertPaddlePrice(response.update_summary.charge.amount, response.currency_code);
          this.upgradePrice.nextFullBill = convertPaddlePrice(response.recurring_transaction_details.totals.total, response.currency_code);
          this.upgradePrice.chargesToday = convertPaddlePrice(response.update_summary.result.amount, response.currency_code);
          this.nextBillDate = this.subscription.next_billed_at;
          this.nextBillDate = dayjs(this.nextBillDate).format('MMM DD, YYYY');
        } else {
          this.step = 1;
        }
      } else {
        this.step = 2;
        await this.$nextTick();
        if (plan === 'Premium') {
          const planId = this.periodAnnual ? PaddleController.productId.PREMIUM_ANNUAL : PaddleController.productId.PREMIUM_MONTHLY;
          this.selectedPlanId = planId;
          this.onProductSelect(planId);
        }
        if (plan === 'Unlimited') {
          const planId = this.periodAnnual ? PaddleController.productId.UNLIMITED_ANNUAL : PaddleController.productId.UNLIMITED_MONTHLY;
          this.selectedPlanId = planId;
          this.onProductSelect(planId);
        }
      }
    },
    onProductSelect(planId) {
      this.checkoutTotal = this.prices[planId];
      PaddleController.openCheckout({
        email: this.user.email,
        addressId: this.userAddressId,
        product: planId,
        customData: {
          tenant_id: this.tenantId,
          user_id: this.user.id,
        },
      });
    },
    async checkScheduledChange({ scheduledChange, planId }) {
      let changed = false;
      if (scheduledChange !== this.subscription?.scheduled_change) {
        changed = true;
      } else if (planId !== this.subscription.plan) {
        changed = true;
      }
      if (!changed) {
        await delay();
        await this.getTenant();
        await this.checkScheduledChange({ scheduledChange, planId });
      }
      return true;
    },
    async checkTenantSubscriptionStatus() {
      if (this.subscription.plan !== this.selectedPlanId) {
        await delay();
        await this.getTenant();
        await this.checkTenantSubscriptionStatus();
      }
      return true;
    },
    onCouponApply(data) {
      this.discountApplied = true;
      this.updatePriceTable(data);
    },
    onCouponRemove(data) {
      this.discountApplied = false;
      this.updatePriceTable(data);
    },
    onCheckoutLoad(data) {
      this.updatePriceTable(data);
    },
    async onCheckoutComplete() {
      this.checkoutCompleted = true;
      await this.checkTenantSubscriptionStatus();
      this.fetchPayments();
      this.showToastMessage({ message: this.$t('subscription.subscribed_successfully'), type: 'success' });
      this.close();
    },
    updatePriceTable(event) {
      const { data } = event;
      const { currency_code } = data;
      const { total } = data.totals;
      this.checkoutTotal = formatPrice(total, currency_code);
    },
  },
};
</script>
