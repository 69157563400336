<template>
  <div id="app" class="h-full min-w-320">
    <router-view />
    <Toast :notificationState="toastNotifications" />
    <ModalsContainer />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';
import text2speech from '@/helpers/text2speech';
import idb from '@/helpers/idb';
import Toast from '@/components/ui/Toast';

export default {
  name: 'App',
  components: { ModalsContainer, Toast },
  computed: {
    ...mapState(['toastNotifications', 'activeDatasourceType', 'org', 'data_sources']),
  },
  methods: {
    ...mapActions(['showToastMessage', 'bootstrap']),
    ...mapMutations(['SET_ACTIVE_DATASOURCE_ACCOUNT_ID', 'SET_ACTIVE_DATASOURCE_DATASOURCE_ID', 'SET_ACTIVE_DATASOURCE']),
    setVisualViewportHeight() {
      const height = window.visualViewport?.height;
      const r = document.querySelector(':root');
      r.style.setProperty('--vvh', `${height}px`);
    },
  },
  created() {
    this.bootstrap();
    text2speech.stopSpeaking();
  },
  mounted() {
    if ('visualViewport' in window) {
      this.setVisualViewportHeight();
      window.visualViewport.addEventListener('resize', () => {
        this.setVisualViewportHeight();
      });
    }
  },
  watch: {
    org: {
      handler() {
        if (this.org) {
          idb.createDatabases(this.org, Object.keys(this.data_sources));
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.grecaptcha-badge {
  display: none !important;
}

.monaco-editor .rename-box {
  display: none;
}
</style>
