<template>
  <img v-if="picture" :src="picture" :class="{ [`w-${size}`]: true, [`h-${size}`]: true }" class="rounded-full" alt="" />
  <Icon v-else name="profile" :class="{ [`w-${size}`]: true, [`h-${size}`]: true }" class="text-gray-400" />
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    picture: String,
    size: {
      type: String,
      default: '4',
    },
  },
};
</script>
