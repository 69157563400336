<template>
  <div class="min-h-full max-w-screen-xxl w-full sm:pt-10 pb-5 max-md:px-3">
    <div class="font-600 text-20 md:text-24 capitalize">{{ $t('onboarding.message_3', { appName }) }}</div>

    <div class="flex mt-5 md:mt-10 flex-wrap">
      <div
        v-for="(datasource, index) in dataSources"
        :key="index"
        class="w-full md:w-280 bg-white border-2 border-solid p-4 items-center cursor-pointer rounded-10 shadow-card flex flex-row my-3 md:mr-6"
        :class="{
          'border-primary': selectedDatasourceType === datasource.key,
          'border-transparent': !selectedDatasourceType || selectedDatasourceType !== datasource.key,
        }"
        @click="onDataSourceClick(datasource)"
      >
        <div class="w-11 h-11">
          <img class="h-11" :src="datasource.icon" :alt="datasource.name" />
        </div>
        <div>
          <div class="ml-4 font-500">{{ datasource.name }}</div>
          <div v-if="getDataSourceDataSourceId(datasource.key)" class="ml-4 font-500">{{ getDataSourceDataSourceId(datasource.key) }}</div>
        </div>
      </div>
    </div>

    <div class="mt-10 flex justify-between">
      <PillButton
        v-if="!!selectedDatasourceType && getDataSourceDataSourceId(selectedDatasourceType)"
        :disabled="!selectedDatasourceType"
        error
        :text="$t('datastore.revoke')"
        class="mr-2"
        @click="onRevokeClick"
      ></PillButton>
      <PillButton v-if="userHasDatasourceAccount" secondary :text="$t('login.skip')" @click="skip"></PillButton>
      <PillButton :disabled="!getDataSourceDataSourceId(selectedDatasourceType)" tertiary :text="$t('login.continue')" class="ml-auto" @click="next"></PillButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { AUTH_TYPE } from '@/constants';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';

export default {
  name: 'Datasource',
  data() {
    return {
      selectedDatasourceType: null,
    };
  },
  computed: {
    ...mapState(['data_sources', 'availableDataSources']),
    appName() {
      return process.env.VUE_APP_COMPANY;
    },
    dataSources() {
      if (!this.availableDataSources) {
        return [];
      }
      return Object.keys(this.availableDataSources)
        .filter((key) => this.availableDataSources[key].auth_type === AUTH_TYPE.OAUTH2)
        .map((key) => {
          return {
            key,
            name: this.availableDataSources[key].name,
            icon: this.availableDataSources[key].logo,
          };
        });
    },
    userHasDatasourceAccount() {
      let hasAccount = false;
      Object.keys(this.data_sources).forEach((type) => {
        Object.values(this.data_sources[type]).forEach((item) => {
          if (item.active && Object.keys(item.accounts).length) {
            hasAccount = true;
          }
        });
      });
      return hasAccount;
    },
    userDataSourceCount() {
      return Object.keys(this.data_sources).length;
    },
    userDataSources() {
      const dataSources = [];
      Object.entries(this.data_sources).forEach(([type, item]) => {
        Object.values(item).forEach((dsItem) => {
          if (dsItem.active) {
            if (!dataSources.includes(type)) {
              dataSources.push(type);
            }
          }
        });
      });
      return dataSources;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'revokeDataSourceAccess', 'oAuthRedirect', 'fetchDatasourceAccounts']),
    ...mapMutations(['SET_ACTIVE_DATASOURCE_ACCOUNT_ID', 'SET_ACTIVE_DATASOURCE_DATASOURCE_ID', 'SET_ACTIVE_DATASOURCE']),
    onRevokeClick() {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('datastore.confirm_revoke_access_message'),
        info: null,
        onConfirm: () => this.confirmRevoke(),
      });
    },
    async confirmRevoke() {
      try {
        await this.revokeDataSourceAccess({ type: this.selectedDatasourceType, datasource_id: this.getDataSourceDataSourceId(this.selectedDatasourceType) });
        this.selectedDatasourceType = null;
        this.selectedAccountId = null;
      } catch (e) {
        this.showToastMessage({ title: this.$t('datastore.failed_to_revoke_account'), type: 'error' });
      }
    },
    onDataSourceClick(datasource) {
      this.selectedDatasourceType = datasource.key;
      if (!this.userDataSources.includes(datasource.key) || (this.userDataSources.includes(datasource.key) && this.data_sources[datasource.key]?.[this.selectedAccountId]?.active === false)) {
        this.selectDataSource(datasource);
      }
    },
    selectDataSource(datasource) {
      this.oAuthRedirect({ type: datasource.key });
    },
    getDataSourceDataSourceId(type) {
      if (!this.userDataSourceCount) {
        return null;
      }
      if (!this.userDataSources || !this.userDataSources.includes(type)) {
        return null;
      }

      const items = [];
      Object.entries(this.data_sources[type]).forEach(([datasourceId, item]) => {
        if (item.active) {
          items.push(datasourceId);
        }
      });
      return items[0];
    },
    async next() {
      this.$router.replace({ query: { type: this.selectedDatasourceType } });
      this.$emit('next');
    },
    skip() {
      this.$emit('skip');
    },
  },
  watch: {
    bootstrapped: {
      handler() {
        if (this.bootstrapped) {
          const { type } = this.$route.query;
          if (type) {
            this.selectedDatasourceType = type;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
