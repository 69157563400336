<template>
  <label class="checkbox flex flex-row items-center relative cursor-pointer text-14 min-h-6 select-none" :class="{ 'cursor-not-allowed opacity-50': disabled, 'h-6 w-6': !indent, 'pl-9': indent }">
    <input type="checkbox" :name="name" class="absolute opacity-0 h-0 w-0" :value="modelValue" @change="updateInput" :checked="modelValue" :disabled="disabled" />
    <span class="checkmark absolute top-0 left-0 h-6 w-6 bg-gray-100 border border-gray-400 rounded-full" :class="{ 'border-primary border-opacity-40': modelValue }"></span>
    <span v-if="label">{{ label }}</span>
    <slot />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  emits: ['update:modelValue'],
  props: {
    disabled: Boolean,
    modelValue: [Boolean],
    label: {
      type: String,
      default: '',
    },
    name: String,
  },
  data() {
    return {
      indent: false,
    };
  },
  created() {
    this.indent = !!this.label || !!this.$slots.default;
  },
  methods: {
    updateInput() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style lang="scss">
.checkbox:hover input ~ .checkmark {
  background-color: #ebebec;
}

.checkbox.opacity-50:hover input ~ .checkmark {
  background-color: #fafafa !important;
}

.checkbox input:checked ~ .checkmark {
  background-color: var(--color-primary);
}

.checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-radius: 2px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
