<template>
  <button
    @click.stop="$emit('click')"
    class="rounded-2 focus:outline-none flex bg-indigo-200 sm:hover:bg-indigo-300 py-1 px-5 text-14 items-center justify-center h-9 font-700 leading-24"
    :class="{ 'pl-2': icon, 'bg-indigo-700 text-white hover:text-indigo-500': primary, 'text-primary': !primary }"
    :disabled="disabled"
  >
    <Icon v-if="icon" :name="icon" class="text-indigo-500 w-6 h-6"></Icon>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    icon: String,
    primary: Boolean,
    disabled: Boolean,
  },
};
</script>
