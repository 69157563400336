<template>
  <div class="text-700">
    <template v-if="variable"><VariableChip :name="variable" :index="5" /></template>
    <template v-else
      ><span class="text-red-600 font-700">{{ $t('not_set') }}</span></template
    >
    <span class="text-pink-500 mx-1">{{ $t('equals') }}</span>
    <span v-if="value.context">
      {{ value.context }}
      <VariableChip v-if="value.datatable" :name="value.datatable" :index="5" />
    </span>
    <span v-else class="text-red-600 font-700">{{ $t('not_set') }}</span>
  </div>
</template>

<script>
export default {
  name: 'EvaluateCapture',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    payload() {
      return this.item.payload;
    },
    variable() {
      return this.payload.variable;
    },
    value() {
      return this.payload.value;
    },
  },
};
</script>
