import { useModal } from 'vue-final-modal';

const ModalPlugin = {
  // eslint-disable-next-line
  install: function (app, options) {
    const { appInstance } = options;

    const modalContainer = document.createElement('div');
    modalContainer.id = 'modal-wrapper';
    document.getElementsByTagName('body')[0].appendChild(modalContainer);

    if (!appInstance) {
      console.error('appInstance parameter must be required!'); // eslint-disable-line
      return;
    }
    // eslint-disable-next-line func-names
    app.config.globalProperties.$showModal = function (Component, props, listeners = {}) {
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const events = {};
      Object.keys(listeners).forEach((e) => {
        events[`on${capitalizeFirstLetter(e)}`] = listeners[e];
      });

      const modal = useModal({
        component: Component,
        attrs: {
          ...props,
          ...events,
          onClose: () => {
            modal.destroy();
          },
          onCancel: () => {
            modal.destroy();
          },
          modalId: 'test1',
        },
      });
      modal.open();
      return {
        close: modal.destroy,
      };
    };
  },
};

export default ModalPlugin;
