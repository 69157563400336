<template>
  <div class="text-700 flex items-center">
    <template v-if="!leftText || !rightText"> {{ $t('evaluate_editor.api') }} </template>
    <template v-else>
      <span><VariableChip :name="leftText" :index="5" class="mr-1" /></span> &nbsp; <span class="text-pink-500">{{ $t('equals') }}</span> &nbsp; <span>{{ rightText }}</span>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EvaluateApi',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    ...mapState(['selectedIntentDefinition', 'availableDataSources']),
    leftText() {
      return this.payload.variable;
    },
    rightText() {
      const ds = this.availableDataSources?.[this.payload?.type]?.name || '';
      const aiConnect = this.payload?.value?.aiConnect;
      if (aiConnect) {
        return `${ds} Data (AI Auto)`;
      }
      return `${ds} Data`;
    },
    payload() {
      return this.item.payload;
    },
  },
};
</script>
