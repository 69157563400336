<template>
  <div>
    Send Email <span class="font-600">{{ emailSubject }}</span> via <span class="font-600">{{ emailTemplate }}</span> template
  </div>
</template>

<script>
export default {
  name: 'EvaluateEmail',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    payload() {
      return this.item.payload;
    },
    emailTemplate() {
      return this.payload?.email_template || '';
    },
    emailTo() {
      return this.payload?.to?.[0] || '';
    },
    emailSubject() {
      return this.payload?.subject || '';
    },
  },
};
</script>
