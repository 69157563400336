<template>
  <div class="flex justify-end">
    <Icon class="w-4 h-4 text-gray-500 mt-2 mx-1 cursor-pointer" name="thumb_up" @click="openFeedbackModal(true)"></Icon>
    <Icon class="w-4 h-4 text-gray-500 mt-2 mx-1 cursor-pointer" name="thumb_down" @click="openFeedbackModal(false)"></Icon>
  </div>
</template>

<script>
import FeedbackModal from '@/components/feedback/Feedback.vue';

export default {
  name: 'FeedbackBox',
  props: {
    feedbackData: Object,
  },
  methods: {
    openFeedbackModal(sentiment) {
      this.$showModal(FeedbackModal, {
        item: this.feedbackData,
        sentiment,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
