<template>
  <transition
    v-bind="$attrs"
    :enter-active-class="enterActiveClass"
    :leave-active-class="leaveActiveClass"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot></slot>
  </transition>
</template>
<script>
export default {
  name: 'FadeTransition',
  inheritAttrs: false,
  props: {
    enterActiveClass: {
      type: String,
      default: 'transition-all ease-out duration-300',
    },
    leaveActiveClass: {
      type: String,
      default: 'transition-all ease-in duration-200',
    },
  },
};
</script>
