<template>
  <div class="text-700 flex items-center">{{ $t('evaluate_editor.response') }}</div>
</template>

<script>
export default {
  name: 'EvaluateResponse',
  props: {
    item: Object,
    evaluate: Array,
  },
  computed: {
    payload() {
      return this.item.payload;
    },
  },
};
</script>
