<template>
  <div class="max-w-screen-xxl w-full sm:pt-10 pb-5 max-md:px-3">
    <div class="mt-5 md:mt-10 w-full">
      <div class="w-full h-220 rounded-t-10 bg-primary relative overflow-hidden flex items-center">
        <div class="mx-2 md:mx-10 rounded-full w-[80px] h-[80px] md:w-[130px] md:h-[130px] bg-[#BC5CDA35] flex items-center justify-center flex-shrink-0">
          <div class="rounded-full w-[70%] h-[70%] bg-white flex items-center justify-center">
            <svg class="w-[40%] h[40%]" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_345_5635)">
                <!-- eslint-disable -->
                <path
                  d="M13.2822 24L0.367816 11.36C-0.122605 10.88 -0.122605 10.12 0.367816 9.68L4.41379 5.72C4.90421 5.24 5.68072 5.24 6.13027 5.72L13.2822 12.72L25.8697 0.36C26.3602 -0.12 27.1367 -0.12 27.5862 0.36L31.6322 4.32C32.1226 4.8 32.1226 5.56 31.6322 6L13.2822 24Z"
                  fill="#1A8CFF"
                />
              </g>
              <defs>
                <clipPath id="clip0_345_5635">
                  <rect width="32" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="text-24 md:text-36 text-off-white z-1">Payment succesful, <br />welcome to fidsy!</div>
        <img :src="bubbleIcon" class="absolute -right-20 -bottom-20 md:-right-30 :-bottom-30 w-250 md:w-400" />
      </div>
      <div class="bg-white w-full p-8 rounded-b-10 shadow-card">
        <div class="text-13 mb-1">Subscription Type</div>
        <ListCard height-class="h-14">
          <img :src="bubbleIcon" class="w-8 h-8" />
          <div class="font-600 ml-2">{{ planNames[subscription.plan] }}</div>
          <div class="ml-auto">{{ priceText }}</div>
        </ListCard>
        <div class="text-13 mb-1 mt-5">Last Payment</div>
        <ListCard height-class="h-14">
          <div v-if="lastPayment">{{ dayjs(lastPayment.started_at).format('MMM DD, YYYY') }}</div>
          <div class="ml-auto cursor-pointer md:hover:text-primary text-indigo-900 flex items-center" @click="getInvoice">
            <Spinner v-if="invoiceDownloading" class="w-4 mr-2" />
            <span>{{ $t('invoice') }}</span>
          </div>
        </ListCard>
        <div class="text-13 mb-1 mt-5">Renewal Date</div>
        <ListCard height-class="h-14">
          <div v-if="lastPayment">{{ dayjs(subscription.next_billed_at).format('MMM DD, YYYY') }}</div>
        </ListCard>
      </div>
      <div class="mt-8 flex justify-end">
        <PillButton tertiary :text="$t('login.continue')" class="ml-3" @click="next"></PillButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import PaddleController from '@/helpers/paddle';
import { convertPaddlePrice } from '@/helpers/pricing';
import { apiGetInvoice } from '@/helpers/api';

const { PREMIUM_MONTHLY, PREMIUM_ANNUAL, UNLIMITED_ANNUAL, UNLIMITED_MONTHLY } = PaddleController.productId;

export default {
  name: 'PaymentSucceed',
  data() {
    return {
      invoiceDownloading: false,
      planNames: {
        [PREMIUM_MONTHLY]: 'Premium Monthly',
        [PREMIUM_ANNUAL]: 'Premium Annual',
        [UNLIMITED_MONTHLY]: 'Unlimited Monthly',
        [UNLIMITED_ANNUAL]: 'Unlimited Annual',
      },
    };
  },
  created() {
    this.fetchPayments();
  },
  computed: {
    ...mapState(['subscription', 'payments']),
    bubbleIcon() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
    priceText() {
      return convertPaddlePrice(this.subscription.product_unit_price_amount, this.subscription.currency_code);
    },
    lastPayment() {
      return this.payments[0];
    },
  },
  methods: {
    ...mapActions(['fetchPayments']),
    convertPaddlePrice,
    dayjs,
    next() {
      this.$emit('next');
    },
    getInvoice() {
      this.invoiceDownloading = true;
      apiGetInvoice({ transaction_id: this.subscription.transaction_id }).then((res) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = res.data.url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          this.invoiceDownloading = false;
        }, 100);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bg-payment-succeed {
  background-size: 100%;
  background-position: center center;
}
</style>
