<template>
  <DefaultNoHeaderLayout class="onboarding">
    <div class="flex flex-col max-w-screen-xxl h-screen mx-auto">
      <div class="h-25 py-6 px-10 flex justify-between items-center">
        <img :src="logo" :alt="appName" class="h-11" />
        <Icon name="logout" class="text-gray-600 w-5 h-4 cursor-pointer" :title="$t('logout')" @click="onLogoutClick" />
      </div>
      <div v-if="bootstrapped" class="grid px-1 sm:px-4 lg:px-10 mx-auto w-full">
        <FadeTransition enterActiveClass="transition-all ease duration-500 delay-500" leaveActiveClass="transition-all ease duration-500">
          <Register v-if="step === 0" class="col-start-1 col-end-1 row-start-1 row-end-1" @next="step = 1" />
        </FadeTransition>
        <FadeTransition enterActiveClass="transition-all ease duration-500 delay-500" leaveActiveClass="transition-all ease duration-500">
          <Datasource v-if="step === 1" class="col-start-1 col-end-1 row-start-1 row-end-1" @next="step = 2" @skip="onDatasourceSkip" />
        </FadeTransition>
        <FadeTransition enterActiveClass="transition-all ease duration-500 delay-500" leaveActiveClass="transition-all ease duration-500">
          <Account v-if="step === 2" class="col-start-1 col-end-1 row-start-1 row-end-1" @next="step = 3" @back="step = 1" />
        </FadeTransition>
        <FadeTransition enterActiveClass="transition-all ease duration-500 delay-500" leaveActiveClass="transition-all ease duration-500">
          <Subscription v-if="step === 3" class="col-start-1 col-end-1 row-start-1 row-end-1" @next="step = 4" @free="complete" />
        </FadeTransition>
        <FadeTransition enterActiveClass="transition-all ease duration-500 delay-500" leaveActiveClass="transition-all ease duration-500">
          <PaymentSucceed v-if="step === 4" class="col-start-1 col-end-1 row-start-1 row-end-1" @next="complete" />
        </FadeTransition>
      </div>
    </div>
  </DefaultNoHeaderLayout>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import FadeTransition from '@/components/transitions/FadeTransition';
import Register from '@/components/onboarding/Register.vue';
import Datasource from '@/components/onboarding/Datasource.vue';
import Account from '@/components/onboarding/Account.vue';
import Subscription from '@/components/onboarding/Subscription.vue';
import PaymentSucceed from '@/components/onboarding/PaymentSucceed.vue';
import { SUBSCRIPTION_STATES } from '@/constants';

export default {
  name: 'Onboarding',
  components: { PaymentSucceed, Subscription, Account, Datasource, Register, FadeTransition },
  data() {
    return {
      step: 0,
      height: 0,
    };
  },
  computed: {
    ...mapState(['data_sources', 'availableDataSources', 'bootstrapped', 'locale', 'user', 'environment', 'subscription']),
    ...mapGetters(['getOnboardingComplete', 'getRegistrationComplete']),
    appName() {
      return process.env.VUE_APP_COMPANY;
    },
    logo() {
      return process.env.VUE_APP_LOGO;
    },
  },
  mounted() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    ...mapActions(['oAuthRedirect', 'fetchDatasourceAccounts', 'revokeDataSourceAccess', 'addAccountToDatasource', 'showToastMessage', 'completeOnboarding', 'setFlag', 'logout', 'queryIntent']),
    ...mapMutations(['SET_ACTIVE_DATASOURCE_ACCOUNT_ID', 'SET_ACTIVE_DATASOURCE_DATASOURCE_ID', 'SET_ACTIVE_DATASOURCE']),
    onDatasourceSkip() {
      if (['dev', 'app'].includes(this.environment)) {
        this.step = 3;
      } else {
        this.skip();
      }
    },
    onLogoutClick() {
      this.$showModal(DeleteModal, { title: this.$t('logout'), subtitle: this.$t('confirm_logout_message'), confirm: this.$t('logout'), onConfirm: () => this.logout({ clearIdb: true }) });
    },
    skip() {
      this.complete();
    },
    async complete() {
      let hasAccount = false;
      let accountId;
      let datasourceId;
      let datasourceType;
      let modelId;
      Object.keys(this.data_sources).forEach((t) => {
        Object.values(this.data_sources[t]).forEach((item) => {
          if (item.active && Object.keys(item.accounts).length) {
            hasAccount = true;
            const { account_id, datasource_id, type, properties = {} } = Object.values(item.accounts)[0];
            accountId = account_id;
            datasourceId = datasource_id;
            datasourceType = type;
            if (properties?.MODEL_ID) {
              modelId = properties.MODEL_ID;
            }
          }
        });
      });

      if (!this.getOnboardingComplete) {
        if (hasAccount) {
          await this.completeOnboarding({ accountId, datasource_id: datasourceId, type: datasourceType, modelId });
        }
      } else if (hasAccount) {
        this.SET_ACTIVE_DATASOURCE_ACCOUNT_ID(accountId);
        this.SET_ACTIVE_DATASOURCE_DATASOURCE_ID(datasourceId);
        this.SET_ACTIVE_DATASOURCE(datasourceType);
        this.queryIntent({ event: 'LaunchRequest' });
      }
      this.$router.replace('/');
    },
  },
  watch: {
    bootstrapped: {
      handler() {
        if (this.bootstrapped) {
          if (this.getRegistrationComplete) {
            this.step = 1;
          }
          const { type } = this.$route.query;
          if (type) {
            this.step = 2;
          }

          if (this.subscription.status === SUBSCRIPTION_STATES.ACTIVE && this.subscription.plan !== 'FREE') {
            this.complete();
          }
        }
      },
      immediate: true,
    },
    step() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.onboarding {
  background-image: url('@/assets/onboarding/onboarding-bg.png');
  background-repeat: no-repeat;
  background-position: 0 94%;
  background-size: auto 61%;
}
</style>
