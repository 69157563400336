<template>
  <div :id="`swiper-${id}`" class="swiper pb-2">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <slot />
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination h-5 !-bottom-1"></div>
  </div>
</template>

<script>
import { Swiper as SwiperLib } from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import uuidv4 from '@/helpers/uuid';

export default {
  name: 'Swiper',
  props: {
    spaceBetween: {
      type: Number,
      default: null,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    centeredSlides: {
      type: Boolean,
      default: false,
    },
    slidesPerView: {
      type: [Number, String],
      default: 1,
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      id: uuidv4(),
      swiper: null,
    };
  },
  mounted() {
    SwiperLib.use([Pagination]);
    this.swiper = new SwiperLib(`#swiper-${this.id}`, {
      direction: 'horizontal',
      loop: this.loop,
      initialSlide: this.initialSlide,
      slidesPerView: this.slidesPerView,
      centeredSlides: this.centeredSlides,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      spaceBetween: this.spaceBetween,
    });
    this.swiper.on('slideChange', (e) => {
      this.$emit('slideChange', e);
    });
  },
  methods: {
    slideNext() {
      this.swiper.slideNext();
    },
    slideTo(index, speed) {
      this.swiper.slideTo(index, speed);
    },
  },
};
</script>

<style scoped lang="scss">
.swiper {
  --swiper-pagination-bullet-width: 36px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-border-radius: 9999px 9999px 0 0;
  --swiper-pagination-color: var(--color-primary);
}
</style>
