import { email, minValue, maxValue, minLength, maxLength, password } from '@/helpers/validators';
import i18n from '@/plugins/i18n';

const VALIDATORS = {
  required: (value, errorMessage = i18n.global.t('validation.required')) => !!value || errorMessage,
  email: (value, errorMessage = i18n.global.t('validation.email')) => email(value) || errorMessage,
  password: (value, errorMessage = i18n.global.t('validation.password')) => password(value) || errorMessage,
  minValue: (min) => {
    return (value, errorMessage = i18n.global.t('validation.min_value', { min })) => minValue(value, min) || errorMessage;
  },
  maxValue: (max) => {
    return (value, errorMessage = i18n.global.t('validation.max_value', { max })) => maxValue(value, max) || errorMessage;
  },
  minLength: (length) => {
    return (value, errorMessage = i18n.global.t('validation.min_length', { length })) => minLength(value, length) || errorMessage;
  },
  maxLength: (length) => {
    return (value, errorMessage = i18n.global.t('validation.max_length', { length })) => maxLength(value, length) || errorMessage;
  },
};

const parseStringRules = ($rules) => {
  return $rules.split('|').map((rule) => {
    if (rule.includes(':')) {
      const ruleSplit = rule.split(':');
      const ruleName = ruleSplit.shift();
      return { validate: VALIDATORS[ruleName](...ruleSplit) };
    }
    return { name: rule };
  });
};

const normalizeRules = ($rules) => {
  let rules = [];
  if (typeof $rules === 'string') {
    rules = parseStringRules($rules);
  } else if (Array.isArray($rules)) {
    $rules.forEach((rule) => {
      if (typeof rule === 'string') {
        rules = [...rules, ...parseStringRules(rule)];
      } else if (typeof rule === 'object' && !Array.isArray(rule)) {
        rules.push({
          name: rule.name,
          errorMessage: rule?.errorMessage || undefined,
          validate: rule?.validate || undefined,
        });
      }
    });
  }
  return rules;
};

const validate = async (value, $rules) => {
  const rules = normalizeRules($rules);
  const errors = [];
  const failedRules = {};

  // eslint-disable-next-line no-restricted-syntax
  for await (const rule of rules) {
    if (rule.validate) {
      const result = await rule.validate(value, rule.errorMessage);
      if (result !== true) {
        errors.push(result);
        failedRules[rule] = result;
      }
    } else {
      const result = await VALIDATORS[rule.name](value, rule.errorMessage);
      if (result !== true) {
        errors.push(result);
        failedRules[rule.name] = result;
      }
    }
  }

  return {
    valid: !errors.length,
    errors,
    failedRules,
  };
};

export const validator = {
  async validate(field) {
    return validate(field.value, field.rules);
  },
  async validateAll(items) {
    return new Promise((resolve) => {
      const promises = [];

      items.forEach((field) => {
        promises.push(validate(field.value, field.rules));
      });
      Promise.all(promises).then((values) => {
        const errors = {};
        values.forEach((value, index) => {
          if (items[index].vid) errors[items[index].vid] = value.failedRules || {};
        });
        resolve({
          valid: values.every((v) => v.valid === true),
          errors,
        });
      });
    });
  },
  extend(name, validatorFn) {
    VALIDATORS[name] = validatorFn;
  },
};

// console.log('null validate', await validate(null, 'required'));
// console.log('required-email validate', await validate('asd', 'required|email'));
// console.log('required-email validate', await validate('muammer@muammer.com', 'required|email'));
// console.log('minvalue 2', await validate(2, [{ validate: VALIDATORS.minValue(3) }]));
// console.log('maxlength', await validate('Muammer', [{ validate: VALIDATORS.maxLength(3) }]));
// console.log('minLength', await validate('Muammer', [{ validate: VALIDATORS.minLength(3) }]));
// console.log('minValue', await validate(2, [{ validate: VALIDATORS.minValue(3) }]));
// console.log('minValue', await validate(6, 'minValue:4'));

export default validator;
