<template>
  <div>
    <slot v-bind:validate="validate" v-bind:validateField="validateField" v-bind:errors="errors" :validating="validating" :dirty="dirty"></slot>
  </div>
</template>
<script>
import { validator } from '@/helpers/validation';

export default {
  name: 'ValidationObserver',
  data() {
    return {
      refs: {},
      errors: {},
      validating: false,
      dirty: false,
    };
  },
  props: {
    validateOnInput: Boolean,
    validateOnBlur: Boolean,
  },
  provide() {
    return {
      validationObserver: {
        getRefs: () => this.refs,
        isDirty: () => this.dirty,
        subscribe: this.subscribe,
        unsubscribe: this.unsubscribe,
        getFieldError: this.getFieldError,
        validateField: this.validateField,
        reset: this.reset,
        validateOnInput: this.validateOnInput,
        validateOnBlur: this.validateOnBlur,
      },
    };
  },
  methods: {
    reset() {
      this.errors = {};
    },
    getFieldError(vid) {
      return this.errors[vid];
    },
    subscribe(subscriber) {
      this.refs[subscriber.$data.vid] = subscriber;
    },
    unsubscribe(subscriber) {
      delete this.refs[subscriber.$data.vid];
    },
    async validate() {
      this.reset();
      this.dirty = true;
      this.validating = true;
      const fields = Object.keys(this.refs).map((key) => {
        const subscriber = this.refs[key];
        return { value: subscriber.$props.modelValue, rules: subscriber.$props.rules, vid: key };
      });
      const result = await validator.validateAll(fields);
      Object.keys(result.errors).forEach((vid) => {
        if (Object.values(result.errors[vid]).length) {
          this.errors[this.refs[vid].$props.name || vid] = Object.values(result.errors[vid])[0];
        } else {
          delete this.errors[this.refs[vid].$props.name || vid];
        }
      });

      this.validating = false;
      return result;
    },
    async validateField(vid, value) {
      this.validating = true;
      const subscriber = this.refs[vid];
      const field = { value: value !== undefined ? value : subscriber.$props.modelValue, rules: subscriber.$props.rules, vid };

      const result = await validator.validate(field);
      if (result.errors.length) {
        this.errors[subscriber.$props.name || vid] = result.errors[0];
      } else {
        delete this.errors[subscriber.$props.name || vid];
      }

      this.validating = false;
      return result;
    },
  },
};
</script>
