import mitt from 'mitt';
import uuidv4 from '@/helpers/uuid';

export default {
  data() {
    return {
      vid: uuidv4(),
      eventbus: mitt(),
    };
  },
  props: {
    rules: {
      type: [String, Array],
    },
    name: {
      type: String,
    },
  },
  inject: {
    validationObserver: { default: undefined },
  },
  created() {
    if (this.validationObserver && this.rules) {
      this.validationObserver.subscribe(this);
      this.eventbus.on('input', this.$onInput);
      this.eventbus.on('blur', this.$onBlur);
    }
  },
  beforeUnmount() {
    if (this.validationObserver) {
      this.validationObserver.unsubscribe(this);
      this.eventbus.on('input', this.$onInput);
      this.eventbus.on('blur', this.$onBlur);
    }
  },
  methods: {
    async $onInput(value) {
      if (this.validationObserver.validateOnInput || this.validationObserver.isDirty()) {
        this.validationObserver.validateField(this.$data.vid, value);
      }
    },
    $onBlur() {
      if (this.validationObserver.validateOnBlur || this.validationObserver.isDirty()) {
        this.validationObserver.validateField(this.$data.vid, this.value);
      }
    },
  },
};
