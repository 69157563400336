<template>
  <div class="w-full sm:rounded-14 sm:shadow-2xl flex flex-col items-center flex-grow-0 bg-white flex-shrink-0">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>
