<template>
  <div id="conversation-container" class="overflow-auto flex-grow" :class="{ 'flex-grow': conversationKeys.length }">
    <Conversation />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Conversation from '@/components/conversation/Conversation';

export default {
  name: 'ConversationView',
  components: { Conversation },
  computed: {
    ...mapState(['conversation']),
    conversationKeys() {
      return Object.keys(this.conversation);
    },
  },
  created() {
    this.$router.replace({ hash: '', query: this.$route.query });
  },
};
</script>

<style scoped lang="scss"></style>
