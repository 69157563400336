import { CURRENCY_FRACTIONAL_DIGIT_COUNT } from '@/constants';
import { selectedLocale } from '@/plugins/i18n';

export const padTrailingZeros = (value, digitCount = CURRENCY_FRACTIONAL_DIGIT_COUNT, locale = selectedLocale) => {
  const formatted = new Intl.NumberFormat(locale, {
    minimumFractionDigits: digitCount,
  }).format(value);
  return formatted !== 'NaN' ? formatted : String(value);
};

export const formatPrice = (val, currency) => {
  const formatter = new Intl.NumberFormat(new Intl.NumberFormat().resolvedOptions().locale, {
    style: 'currency',
    currency,
  });
  return formatter.format(val);
};

export const convertPaddlePrice = (text, currency) => {
  if (text !== '0') {
    const textArr = text.split('');
    textArr.splice(text.length - 2, 0, '.');
    text = textArr.join('');
    return formatPrice(parseFloat(text), currency);
  }
  return text;
};

export const getAmount = (payment) => {
  const total = payment?.details.totals.total;
  const currency = payment?.details.totals.currency_code;
  if (total !== '0') {
    return convertPaddlePrice(total, currency);
  }
  return total;
};

export default padTrailingZeros;
