<template>
  <label
    class="radio-button flex flex-row items-start relative cursor-pointer text-14 select-none"
    :class="{ 'cursor-not-allowed opacity-50': disabled, 'h-5 w-5': label.length === 0, 'pl-9': label.length > 0 }"
  >
    <input type="radio" :name="name" class="absolute opacity-0 h-0 w-0" :value="value" @change="updateInput" :checked="shouldBeChecked" :disabled="disabled" />
    <span class="checkmark absolute top-0 left-0 h-5 w-5 bg-gray-100 border border-gray-400 rounded-full" :class="{ 'border-primary border-opacity-40': shouldBeChecked }"></span>
    <span v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'RadioButton',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    disabled: Boolean,
    value: [String, Number],
    modelValue: [String, Number],
    label: {
      type: String,
      default: '',
    },
    name: String,
  },
  methods: {
    updateInput() {
      this.$emit('update:modelValue', this.value);
    },
  },
  computed: {
    shouldBeChecked() {
      // Using double equals as the model could be number and string at the same time
      return this.modelValue == this.value; // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
.radio-button:hover input ~ .checkmark {
  background-color: #ebebec;
}

.radio-button.opacity-50:hover input ~ .checkmark {
  background-color: #fafafa !important;
}

.radio-button input:checked ~ .checkmark {
  background-color: var(--color-primary);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-button input:checked ~ .checkmark:after {
  display: block;
}

.radio-button .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
