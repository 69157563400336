<template>
  <button
    @click.stop="$emit('click')"
    class="bg-none border-none focus:outline-none text-14 p-0 leading-14 hover:underline whitespace-nowrap"
    :class="{
      'text-primary': !disabled,
      'text-gray-500 cursor-not-allowed': disabled,
    }"
    :disabled="disabled"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'LinkButton',
  props: {
    text: String,
    disabled: Boolean,
  },
  emits: ['click'],
};
</script>
