<template>
  <div class="help-item" ref="markdown"></div>
</template>

<script>
import { mapActions } from 'vuex';
import markdownit from 'markdown-it';

export default {
  name: 'HelpItem',
  props: {
    id: [String, Number],
  },
  data() {
    return {
      text: null,
    };
  },
  async mounted() {
    this.text = await this.fetchHelpQuestion({ id: this.id });
    const md = markdownit();
    this.$refs.markdown.innerHTML = md.render(this.text);
  },
  methods: {
    ...mapActions(['fetchHelpQuestion']),
  },
};
</script>

<style lang="scss">
.help-item {
  h1 {
    @apply text-22;
    @apply leading-30;
    @apply font-500;
    @apply text-primary;
    @apply mb-5;
    @apply pt-2;
  }
  h2 {
    @apply text-18;
    @apply font-500;
    @apply mb-2;
  }
  h3 {
    @apply text-16;
  }

  img {
    max-width: 900px;
    @apply mx-5;
    @apply my-6;
    @apply rounded-4;
  }
}
</style>
