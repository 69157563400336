<template>
  <div v-if="!incoming" class="bg-white rounded-22 rounded-br-none ml-auto flex flex-row justify-end items-start mb-2 mt-2 text-14 leading-20 shadow-md">
    <div class="w-9 h-9 pt-1 flex-shrink-0 flex-grow-0 ml-1">
      <Avatar size="9" :picture="avatar" />
    </div>
    <div class="mr-2 px-3 py-3 flex items-center">{{ text }}</div>
  </div>

  <div v-else class="inline-flex flex-row justify-start items-start mt-2 mr-auto shadow-md rounded-22 rounded-tl-none" :class="{ 'bg-red-200': !!error, 'bg-primary bg-opacity-10': !error }">
    <div v-if="error || showIcon" class="w-9 h-9 pt-3 ml-2 flex-shrink-0 flex-grow-0 flex items-center">
      <img :src="bubbleIcon" class="w-8 h-8 rounded-full" />
    </div>
    <div class="pr-4 py-3 text-14 leading-20 flex items-center" :class="{ 'pl-1': showIcon, 'pl-3': !showIcon }">
      <template v-if="!$slots['default']">
        <span v-html="typewriterHtml"></span>
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversationMessage',
  props: {
    avatar: {
      type: String,
    },
    text: {
      type: String,
    },
    incoming: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    showIcon: {
      type: Boolean,
    },
  },
  data() {
    return {
      typewriterHtml: '',
    };
  },
  computed: {
    bubbleIcon() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
  },
  mounted() {
    if (this.text) {
      this.typewriterHtml = this.text;
    }
  },
};
</script>

<style scoped></style>
