<template>
  <div class="swiper-slide">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SwiperSlide',
};
</script>
