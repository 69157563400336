/* eslint-disable no-restricted-syntax, class-methods-use-this */
/* global Paddle */

class PaddleController {
  constructor() {
    // initialize event list
    this.eventObject = {};
  }

  get isProd() {
    return window.location.hostname === 'app.fidsy.com';
  }

  get vendorId() {
    if (this.isProd) {
      return 108761;
    }
    return 11644;
  }

  get productId() {
    if (this.isProd) {
      return {
        PREMIUM_MONTHLY: 'pri_01hj8v2vs2602n5v46v60pyvmh',
        UNLIMITED_MONTHLY: 'pri_01hj8v1m1gcjattw817bp8qr7r',
        PREMIUM_ANNUAL: 'pri_01hj8v3crrnvyk2q1f577715wx',
        UNLIMITED_ANNUAL: 'pri_01hj8v296w4ytqezgkmzhjxnjw',
      };
    }
    return {
      PREMIUM_MONTHLY: 'pri_01hj6ntej5gw819mjp4r07635v',
      UNLIMITED_MONTHLY: 'pri_01hj6nw2jra1tch1j5v9zfbs4y',
      PREMIUM_ANNUAL: 'pri_01hj6nrtd0d3f95wyfkc2wtnba',
      UNLIMITED_ANNUAL: 'pri_01hj6nvggefykm961478rjrjvk',
    };
  }

  setup() {
    if (!this.isProd) {
      Paddle.Environment.set('sandbox');
    }
    Paddle.Setup({
      token: 'test_7ed842b75a20d6cc120ac710137',
      pwAuth: 'f7d6d32b04aed12cfcedbcfff32d67bb76442920c10837d349',
      eventCallback: (eventData) => {
        console.log(eventData); // eslint-disable-line
        const { name } = eventData;
        this.emit(name, eventData);
      },
    });
  }

  openCheckout(args, successCallback) {
    const { email, addressId, product, customData } = args;
    Paddle.Checkout.open({
      customer: {
        email,
        address: { id: addressId },
      },
      items: [
        {
          priceId: product,
          quantity: 1,
        },
      ],
      customData,
      settings: {
        displayMode: 'inline',
        frameTarget: 'paddle-checkout-container',
        frameStyle: 'position: relative; width: 100%; min-height: 400px;',
        successCallback: (data) => {
          if (successCallback) {
            successCallback(data);
          }
        },
      },
    });
  }

  closeCheckout() {
    Paddle.Checkout.close();
  }

  getPrices() {
    return Paddle.PricePreview({
      items: [
        { quantity: 1, priceId: this.productId.PREMIUM_MONTHLY },
        { quantity: 1, priceId: this.productId.PREMIUM_ANNUAL },
        { quantity: 1, priceId: this.productId.UNLIMITED_MONTHLY },
        { quantity: 1, priceId: this.productId.UNLIMITED_ANNUAL },
      ],
    });
  }

  // publish event
  emit(eventName, ...args) {
    // Get all the callback functions of the current event
    const callbackList = this.eventObject[eventName];

    if (!callbackList) return false;

    // execute each callback function
    for (const callback of callbackList) {
      // pass parameters when executing
      callback(...args);
    }

    return callbackList;
  }

  // Subscribe to events
  on(eventName, callback) {
    // initialize this event
    if (!this.eventObject[eventName]) {
      this.eventObject[eventName] = [];
    }

    // store the callback function of the subscriber
    this.eventObject[eventName].push(callback);
  }
}

export default new PaddleController();
