<template>
  <span class="variable-chip rounded-4 px-1 py-0.5 inline-block" :class="{ [getVariableColor()]: true }">{{ name }}</span>
</template>

<script>
export default {
  name: 'VariableChip',
  props: {
    name: String,
    randomColor: {
      type: Boolean,
      default: true,
    },
    index: Number,
  },
  methods: {
    getVariableColor() {
      const colors = ['bg-green-300', 'bg-red-300', 'bg-pink-200', 'bg-blue-300', 'bg-purple-300', 'bg-gray-200'];
      return colors[Number.isInteger(this.index) ? this.index % colors.length : Math.floor(Math.random() * colors.length) + 1];
    },
  },
};
</script>
<style lang="scss">
.variable-chip {
  &:before {
    content: '{';
  }
  &:after {
    content: '}';
  }
}
</style>
