<template>
  <div class="relative rounded-md border border-gray-300 bg-white">
    <!-- eslint-disable -->
    <div
      class="
        flex flex-row
        justify-start
        items-center
        w-full
        shadow-sm
        h-9
        focus:outline-none focus:shadow-outline-blue focus:border-blue-300
        transition
        ease-in-out
        duration-150
        text-sm
        leading-5
        overflow-hidden
      "
      @click.prevent="activate()"
    >
      <!-- eslint-enable -->
      <ul class="px-1 cursor-pointer flex flex-row items-center w-full">
        <li
          v-for="item in value"
          :key="item"
          class="text-gray-900 select-none relative bg-indigo-200 cursor-pointer hover:text-white hover:bg-indigo-600 py-1 px-2 rounded-4 h-7 flex flex-row items-center mr-2 truncate flex-shrink-0"
        >
          <span class="mr-1">{{ item }}</span>
          <span class="text-red-500" @click.stop="onRemoveItem(item)">
            <Icon name="clear" class="w-3 h-3" />
          </span>
        </li>

        <li class="chips__itemInput min-w-150 w-full">
          <input v-model="searchedText" @focus.prevent="activate" @keyup.esc="deactivate" @blur="deactivate" class="chips__input--fake" type="text" :placeholder="$t('type_to_search')" ref="search" />
        </li>
      </ul>
    </div>

    <div v-if="showList" class="absolute text-sm leading-5 mt-1 w-full rounded-md bg-white shadow-lg h-38 overflow-y-scroll border border-solid border-gray-300">
      <ul>
        <li class="cursor-pointer font-400 block truncate py-2 px-3 hover:text-white hover:bg-indigo-600" v-for="item in filteredAllItems" :key="item" @click="onSelectItem(item)">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
    },
  },
  data() {
    return {
      searchedText: '',
      selectedItems: [],
      showList: false,
    };
  },
  computed: {
    allItems() {
      const items = [...this.items];
      if (this.selectedItems.length) {
        return items.filter((i) => !this.selectedItems.includes(i));
      }
      return items;
    },
    filteredAllItems() {
      if (this.searchedText) {
        return this.allItems.filter((i) => {
          return i.toLowerCase().includes(this.searchedText.toLowerCase());
        });
      }
      return this.allItems;
    },
    valueLength() {
      return this.value.length;
    },
  },
  methods: {
    onSelectItem(item) {
      this.selectedItems.push(item);
      this.$emit('input', this.selectedItems);
      this.searchedText = '';
    },
    onRemoveItem(item) {
      const indexOfItem = this.selectedItems.findIndex((i) => {
        return i === item;
      });
      this.selectedItems.splice(indexOfItem, 1);

      this.$emit('input', this.selectedItems);
    },
    activate() {
      this.searchedText = '';
      this.showList = true;
    },
    async deactivate() {
      setTimeout(() => {
        this.searchedText = '';
        this.showList = false;
      }, 200);
    },
  },
  watch: {
    valueLength: {
      handler() {
        this.selectedItems = this.value;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.chips {
  &__item {
    background-color: rgba(50, 115, 220, 0.2);
    transition: 0.3s;

    &:hover {
      background-color: rgba(50, 115, 220, 0.3);
    }
  }
  &__itemInput {
    display: inline-block;
  }
  &__input--fake {
    border: none;
    width: 100%;
    line-height: 1.42857143;
    font-size: 1rem;
    height: 36px;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.allitems {
  &__list {
    padding: 6px;
    transition: 0.3s;

    &:hover {
      background: rgba(50, 115, 220, 1);
      color: #ffffff;
    }
  }
}
</style>
