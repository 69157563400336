<template>
  <div class="full-screen-modal fixed z-40 top-0 left-0 right-0 bg-white overflow-hidden transform w-screen h-screen min-w-320" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div v-if="closeButton" class="absolute right-10 top-10 rounded-full w-6 h-6 flex justify-center items-center cursor-pointer" @click.stop="$emit('close')">
      <Icon name="clear" class="w-5 h-5" />
    </div>
    <div class="w-full h-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenModal',
  props: {
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.body.classList.add('overflow-hidden');
    this.$emit('after-enter');
  },
  beforeUnmount() {
    document.body.classList.remove('overflow-hidden');
  },
};
</script>
<style lang="scss">
.full-screen-modal {
  background-image: url('../../assets/bg.png');
  background-color: #faf9ff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
