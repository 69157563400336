<template>
  <button
    @click.stop="$emit('click')"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    class="shadow-md rounded-full focus:outline-none flex py-1 px-3 text-14 items-center justify-center h-7 sm:h-9 font-700 leading-24 border border-px bg-white hover:bg-gray-100 border-gray-200"
  >
    <Icon :name="getIcon" class="w-5 h-5 mr-1" :class="{ [iconClass]: true }"></Icon>
  </button>
</template>

<script>
export default {
  name: 'IconToggleButton',
  props: {
    icon: String,
    iconClass: String,
    selected: Boolean,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    getIcon() {
      return this.hover || this.selected ? this.icon : `${this.icon}_gray`;
    },
  },
  emits: ['click'],
};
</script>
