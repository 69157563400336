<template>
  <ValidationObserver v-if="showEditModal" v-slot="{ validate, errors }">
    <Modal
      sheetbelowsm
      :title="$t('settings.address.update_address')"
      close-button
      :primary-button-loading="loading"
      :primary-button-disabled="loading"
      :secondary-button-disabled="loading"
      :primary-button="$t('save')"
      :secondary-button="$t('cancel')"
      @close="showEditModal = false"
      @cancel="showEditModal = false"
      @primary="onEditConfirm(validate)"
      @secondary="showEditModal = false"
    >
      <div class="sm:w-600 px-10">
        <div class="w-full flex flex-col justify-start">
          <FormInput class="mt-3 w-full" v-model="description" maxlength="20" name="address_name" :rules="['required']" :error="errors['address_name']" :label="$t('settings.address.description')" />
        </div>
        <div class="w-full flex flex-col justify-start">
          <FormInput class="mt-3 w-full" v-model="firstLine" name="first_line" :rules="['required']" :error="errors['first_line']" :label="$t('settings.address.first_line')" />
        </div>
        <div class="w-full flex flex-col justify-start">
          <FormInput class="mt-3 w-full" v-model="secondLine" name="second_line" :error="errors['second_line']" :label="$t('settings.address.second_line')" />
        </div>
        <div class="w-full flex max-lg:flex-wrap">
          <div class="w-full lg:w-1/2 lg:mr-2 flex flex-col justify-start">
            <FormInput class="mt-3 w-full" v-model="city" name="city" :error="errors['city']" :label="$t('settings.address.city')" />
          </div>
          <div class="w-full lg:w-1/2 lg:ml-2 flex flex-col justify-start">
            <FormInput class="mt-3 w-full" v-model="postalCode" name="postal_code" :error="errors['postal_code']" :label="$t('settings.address.postal_code')" />
          </div>
        </div>
        <div class="w-full flex max-lg:flex-wrap">
          <div class="w-full lg:w-1/2 lg:mr-2 flex flex-col justify-start">
            <FormInput class="mt-3 w-full" v-model="region" name="region" :error="errors['region']" :label="$t('settings.address.region')" />
          </div>
          <div class="w-full lg:w-1/2 lg:ml-2 flex flex-col justify-start">
            <FormSelect
              :label="$t('settings.address.country')"
              name="country"
              :error="errors['country_code']"
              v-model="countryCode"
              class="mt-3 w-full"
              :options="countries"
              :clearable="false"
              searchable
              :reduce="(option) => option.value"
              appendToBody
            ></FormSelect>
          </div>
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import { COUNTRIES } from '@/constants';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EditAddressModal',
  components: { ValidationObserver },
  data() {
    return {
      fetching: false,
      loading: false,
      showEditModal: false,
      draftAddress: null,
      addressId: null,
      description: null,
      firstLine: null,
      secondLine: null,
      city: null,
      postalCode: null,
      region: null,
      countryCode: null,
      status: null,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.code })),
    };
  },
  emits: ['close'],
  computed: {
    ...mapState(['addresses', 'subscription']),
  },
  created() {
    const subscriptionAddress = this.addresses.find((a) => a.id === this.subscription.address_id);
    if (subscriptionAddress) {
      const { id, description, first_line, second_line, city, postal_code, region, country_code, status } = subscriptionAddress;
      this.addressId = id;
      this.description = description;
      this.firstLine = first_line;
      this.secondLine = second_line;
      this.city = city;
      this.postalCode = postal_code;
      this.region = region;
      this.countryCode = country_code;
      this.status = status;
    }
    this.showEditModal = true;
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchAddresses', 'updateAddress', 'setDefaultAddress']),
    async onEditConfirm(validate) {
      if (validate && typeof validate === 'function') {
        const result = await validate();
        if (!result.valid) return;
      }
      this.loading = true;
      const response = await this.updateAddress({
        address_id: this.addressId,
        description: this.description,
        first_line: this.firstLine,
        second_line: this.secondLine,
        city: this.city,
        postal_code: this.postalCode,
        region: this.region,
        country_code: this.countryCode,
        status: this.status,
      });
      this.loading = false;

      if (response) {
        this.showEditModal = false;
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
