import i18n from '@/plugins/i18n';

let msg;

const speechSupported = window['speechSynthesis'] !== undefined; // eslint-disable-line

if (speechSupported) {
  msg = new SpeechSynthesisUtterance();
  msg.volume = 1; // From 0 to 1
  msg.rate = 0.8; // From 0.1 to 10
  msg.lang = i18n.global.locale;
}

function getVoices() {
  if (speechSupported) {
    const language = i18n.global.locale.split('-')[0];
    return speechSynthesis.getVoices().filter((v) => v.lang.startsWith(language));
  }
  return [];
}

function setVoice(voiceName) {
  const voice = getVoices().find((v) => v.name === voiceName);
  if (msg && voice) {
    msg.voice = voice;
  }
}

function speak(text) {
  if (msg && speechSupported) {
    msg.text = text.replace(/(<([^>]+)>)/gi, '');
    speechSynthesis.speak(msg);
  }
}

function stopSpeaking() {
  try {
    if (speechSupported) {
      speechSynthesis.cancel();
    }
  } catch {} //eslint-disable-line
}

function speakExampleText(text, voiceName) {
  if (speechSupported) {
    stopSpeaking();

    const exampleMsg = new SpeechSynthesisUtterance();
    exampleMsg.volume = 1; // From 0 to 1
    exampleMsg.rate = 0.8; // From 0.1 to 10
    // exampleMsg.pitch = 1.5; // From 0.1 to 10
    exampleMsg.lang = i18n.global.locale;
    exampleMsg.voice = getVoices().find((v) => v.name === voiceName);
    exampleMsg.text = text.replace(/(<([^>]+)>)/gi, '');
    speechSynthesis.speak(exampleMsg);
  }
}

if (speechSupported) {
  speechSynthesis.onvoiceschanged = () => {
    const selectedVoiceName = localStorage.getItem('text2speech_selected_voice_name');
    if (selectedVoiceName) {
      setVoice(selectedVoiceName);
    }
  };
}

export default {
  supported: speechSupported,
  speak,
  stopSpeaking,
  getVoices,
  setVoice,
  speakExampleText,
};
