<template>
  <div class="compact-analysis-card relative flex flex-col justify-start w-full max-w-330 h-full">
    <div class="flex flex-col items-left text-left h-full text-13 relative">
      <template v-if="hasError">
        <div class="p-1 sm:p-2 text-center flex-shrink-0 flex justify-center items-center text-12">{{ questionPhrase }}</div>
        <Divider dense />

        <div v-if="selectAccount" class="flex-grow flex flex-col items-center justify-center">
          <div class="font-500 text-red-700 text-center">{{ errorMessage }}</div>
          <div class="pt-3">{{ $t('collections.select_from_below') }}</div>
          <div class="mt-4 h-10 w-10/12">
            <v-select v-model="account" :placeholder="$t('datastore.select_account')" :clearable="false" v-bind:selected="!!account" :options="getAccounts" class="w-full">
              <template v-slot:option="{ account }">
                <div>{{ account.email }}</div>
                <div>{{ account.name }}</div>
              </template>
            </v-select>
          </div>
          <PillButton tertiary :text="$t('save')" class="mt-3" @click="saveAccount"></PillButton>
        </div>
        <div v-else-if="errorMessage === 'Question not found!'" class="flex-grow flex flex-col items-center justify-center cursor-pointer px-3 text-center">
          <div v-if="!errorMessage">{{ $t('collections.failed_to_load_card') }}</div>
          <div v-else>{{ errorMessage }}</div>
        </div>
        <div v-else class="flex-grow flex flex-col items-center justify-center cursor-pointer px-3 text-center" @click="$emit('reload')">
          <icon name="refresh" class="text-black w-6 h-6 mb-2" />
          <div v-if="!errorMessage">{{ $t('collections.failed_to_load_card') }}</div>
          <div v-else>{{ errorMessage }}</div>
        </div>

        <div class="absolute right-1 bottom-1">
          <IconButton icon="delete" size="4" class="text-primary" @click.stop="onDeleteClick"></IconButton>
        </div>
      </template>
      <template v-else-if="!loading && result">
        <div v-if="questionPhrase" class="p-1 sm:p-2 text-center flex-shrink-0 flex justify-center items-center text-12">{{ questionPhrase }}</div>

        <Divider v-if="questionPhrase" dense />
        <div class="flex flex-col flex-grow items-center relative" :class="{ 'overflow-auto': selectedChartType !== 'table', 'overflow-hidden': selectedChartType === 'table' }" ref="chart-container">
          <Swiper class="w-full h-full" ref="swiper" :loop="supportedChartTypes.length > 1" @slideChange="onSlideChange">
            <SwiperSlide v-for="(displayType, index) in supportedChartTypes" :key="index" class="flex">
              <div
                class="flex-shrink-0 inline-flex items-center justify-center h-full"
                :class="{
                  'w-1/2': showCardText && !['insights', 'text', 'table'].includes(displayType),
                  'w-full': !showCardText || ['insights', 'text', 'table'].includes(displayType),
                  'm-auto': displayType === 'insights',
                }"
              >
                <div v-if="!displayType">{{ result.title }}</div>
                <div v-else-if="displayType === 'table'" class="w-full h-full">
                  <DataTable dense :table="result.table" class="h-full"></DataTable>
                </div>
                <div v-else-if="displayType === 'insights'" class="mb-6 px-2 h-full overflow-auto flex items-center text-center my-auto">
                  <div class="m-auto pb-2 leading-22 text-13" v-for="(insight, index) in result.insights" :key="index" v-html="insight"></div>
                </div>
                <div v-else-if="displayType === 'text'" class="mb-6 px-2 w-full h-full overflow-auto flex items-center text-center m-auto">
                  <div class="m-auto leading-22 text-13" v-html="result.text"></div>
                </div>
                <ChartSelector v-else ref="chart-selector" height-class="" :report="result" :selected-chart-type="displayType" hide-legend />
              </div>
              <div v-if="showCardText && !['insights', 'text', 'table'].includes(displayType)" class="w-1/2 flex-grow flex-shrink-0 flex items-center overflow-auto pl-3">
                <div class="m-auto leading-22 text-13" v-html="result.text"></div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <Divider dense />
      </template>
      <template v-else>
        <div class="flex-grow flex items-center justify-center">
          <Spinner class="w-8" />
        </div>
      </template>
      <div v-if="result">
        <div class="px-3 h-9 flex items-center justify-between w-full">
          <div class="text-12 text-gray-700 flex items-center">
            <img :src="availableDataSources[type].logo" class="w-5 mr-2" alt="type" />
            <span class="text-10">{{ periodText }}</span>
          </div>
          <div class="flex items-center">
            <Icon v-if="autoRefreshIntervalValue" name="update" class="w-5 h-5 mr-2 text-primary" :title="updatedAtLabel" />
            <IconButton
              v-if="result.text && !['table', 'text', 'insights'].includes(selectedChartType)"
              :icon="!showCardText ? 'speaker_notes' : 'speaker_notes_off'"
              class="flex-shrink-0 cursor-pointer text-primary"
              @click="toggleCardText"
            />
            <IconButton size="4" :class="{ hidden: nextChartType !== 'bar' }" icon="view_bar" class="flex-shrink-0 cursor-pointer text-primary" @click="setNextType" />
            <IconButton
              size="4"
              :class="{ hidden: ['bar', 'table', 'pie', 'donut', 'text', 'area'].includes(nextChartType) }"
              icon="view_bar"
              class="flex-shrink-0 cursor-pointer text-primary"
              @click="setNextType"
            />
            <IconButton size="4" :class="{ hidden: nextChartType !== 'pie' && nextChartType !== 'donut' }" icon="view_pie" class="flex-shrink-0 cursor-pointer text-primary" @click="setNextType" />
            <IconButton size="4" :class="{ hidden: nextChartType !== 'table' }" icon="view_table" class="flex-shrink-0 cursor-pointer text-primary" @click="setNextType" />
            <IconButton size="4" :class="{ hidden: nextChartType !== 'text' }" icon="view_txt" class="flex-shrink-0 cursor-pointer text-primary" @click="setNextType" />
            <IconButton size="4" :class="{ hidden: nextChartType !== 'area' }" icon="view_area" class="flex-shrink-0 cursor-pointer text-primary" @click="setNextType" />
            <IconButton size="4" icon="open_in_full" class="flex-shrink-0 cursor-pointer text-primary" @click="$emit('detail', { result, selectedChartType })" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import ChartSelector from '@/components/charts/ChartSelector';
import DataTable from '@/components/conversation/DataTable';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import Swiper from '@/components/ui/Swiper.vue';
import SwiperSlide from '@/components/ui/SwiperSlide.vue';

export default {
  name: 'CompactAnalysisCard',
  components: { SwiperSlide, Swiper, DataTable, ChartSelector },
  props: {
    collectionCard: {
      type: Object,
    },
    loading: Boolean,
    result: Object,
    questionPhrase: String,
    type: String,
    collectionId: String,
    error: Boolean,
    errorMessage: String,
    selectAccount: Boolean,
    grid: Object,
  },
  data() {
    return {
      showAnalysisCard: false,
      showExportModal: false,
      exportChartType: '',
      selectedChartType: null,
      autoRefreshInterval: null,
      account: null,
      showCardText: false,
    };
  },
  beforeUnmount() {
    clearInterval(this.autoRefreshInterval);
  },
  computed: {
    ...mapState(['data_sources', 'availableDataSources', 'collections']),
    splitView() {
      return this.grid.w > 2 && ['pie', 'donut', 'bar', 'line'].includes(this.selectedChartType);
    },
    getAccounts() {
      const accounts = [];
      if (this.type) {
        Object.keys(this.data_sources[this.type]).forEach((email) => {
          Object.values(this.data_sources[this.type][email]?.accounts || {})?.forEach((account) => {
            accounts.push({
              label: account.name,
              value: account.account_id,
              account,
            });
          });
        });
      }
      return accounts;
    },
    updatedAtLabel() {
      const time = dayjs(this.collectionCard.queryTimestamp).format('HH:mm');
      return this.$t('auto_refresh.updated_at', { time });
    },
    autoRefreshIntervalValue() {
      if (this.collectionCard?.interval) {
        return this.collectionCard?.interval;
      }
      return 0;
    },
    periodText() {
      if (!this.collectionId) return '';
      if (this.result?.date) return this.result.date;
      const collection = this.collections.find((col) => col.id === this.collectionId);
      const question = collection.questions.find((q) => q.id === this.collectionCard.id);
      const dateRange = question?.variables?.date1?.date_range || this.collectionCard.date_range;
      return this.$t(`date_ranges.${dateRange}`);
    },
    supportedChartTypes() {
      const types = this.result?.chart?.map((c) => c.chart.type.toLowerCase()) || [];
      if (this.result?.table) {
        types.push('table');
      }
      if (this.result?.image?.url) {
        types.push('image');
      }
      if (this.result?.insights) {
        types.push('insights');
      }
      if (this.result?.text) {
        types.push('text');
      }
      return types;
    },
    hasError() {
      return !this.loading && !!this.error;
    },
    nextChartType() {
      const index = this.supportedChartTypes.indexOf(this.selectedChartType);
      return index + 1 >= this.supportedChartTypes.length ? this.supportedChartTypes[0] : this.supportedChartTypes[index + 1];
    },
  },
  mounted() {
    this.$emit('del');
  },
  methods: {
    ...mapActions(['removeTenantQuestionFromCollection', 'updateTenantQuestion', 'showToastMessage']),
    ...mapMutations(['SET_COLLECTION_SELECTED_CHART_TYPE']),
    toggleCardText() {
      this.showCardText = !this.showCardText;
    },
    onSlideChange(e) {
      this.selectedChartType = this.supportedChartTypes[e.realIndex];
    },
    async saveAccount() {
      const { id, type, interval, phrase, variables } = this.collectionCard;
      const res = await this.updateTenantQuestion({
        id,
        phrase,
        collection_id: this.collectionId,
        type,
        datasource_id: this.account.account.datasource_id,
        account_id: this.account.value,
        interval,
        variables,
      });

      if (res) {
        this.$emit('reload');
      } else {
        this.showToastMessage({ title: this.$t('settings.failed_to_update_account'), type: 'error' });
      }
    },
    onDeleteClick() {
      this.$showModal(DeleteModal, { subtitle: this.$t('collections.remove_collection_question_question'), onConfirm: () => this.removeFromCollection() });
    },
    async removeFromCollection() {
      await this.removeTenantQuestionFromCollection({ collection_id: this.collectionId, id: this.collectionCard.id });
      this.$emit('del');
    },
    startAutoRefreshInterval() {
      this.autoRefreshInterval = setInterval(() => {
        this.$emit('reload');
      }, this.autoRefreshIntervalValue * 60000);
    },
    setNextType() {
      const index = this.supportedChartTypes.indexOf(this.selectedChartType);
      this.selectedChartType = index + 1 >= this.supportedChartTypes.length ? this.supportedChartTypes[0] : this.supportedChartTypes[index + 1];
      this.SET_COLLECTION_SELECTED_CHART_TYPE({ collectionId: this.collectionId, id: this.collectionCard.id, selectedChartType: this.selectedChartType });
      this.$refs.swiper.slideNext();
    },
    async selectChartType(chartType) {
      if (this.hasError) {
        return;
      }
      if (chartType === 'insights') {
        this.selectedChartType = chartType;
      } else if (chartType === 'text') {
        this.selectedChartType = chartType;
      } else if (this.supportedChartTypes.includes(chartType)) {
        this.selectedChartType = chartType;
      } else if (chartType === 'table') {
        this.selectedChartType = chartType;
      } else {
        this.selectedChartType = null;
      }

      const index = this.supportedChartTypes.indexOf(chartType);
      this.$nextTick().then(() => {
        if (index > -1 && this.$refs.swiper) {
          this.$refs.swiper.slideTo(index, 0);
        }
      });
    },
  },
  watch: {
    // eslint-disable-next-line
    'collectionCard.selectedChartType'() {
      if (this.collectionCard.selectedChartType !== this.selectedChartType) {
        this.selectChartType(this.collectionCard.selectedChartType);
      }
    },
    loading: {
      handler() {
        if (!this.loading && this.result) {
          if (this.collectionCard.selectedChartType) {
            this.selectChartType(this.collectionCard.selectedChartType);
          } else {
            this.selectChartType(this.supportedChartTypes?.[0]);
          }
        }
      },
      immediate: true,
    },
    autoRefreshIntervalValue: {
      handler() {
        clearInterval(this.autoRefreshInterval);
        if (this.autoRefreshIntervalValue) {
          this.startAutoRefreshInterval();
        }
      },
      immediate: true,
    },
    grid: {
      handler() {
        if (this.$refs['chart-selector'] && this.$refs['chart-selector']?.setSize) {
          this.$refs['chart-selector']?.setSize();
        }
      },
      deep: true,
    },
    selectedChartType: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.$emit('chart-type-changed', newValue);
        }
      },
    },
  },
};
</script>
