<template>
  <div class="absolute transition-all ease-in duration-200 left-0 bottom-0 right-0 w-full rounded-t-20 bg-off-white" :class="{ 'top-0 h-full': show, 'top-full h-0 overflow-hidden': !show }">
    <div v-if="show" class="p-2 sm:p-6 sm:pt-3 pr-0 sm:pr-0 h-full">
      <div class="text-left h-full flex flex-col">
        <div ref="overflow-container" class="flex-grow overflow-auto pr-2">
          <div v-if="insightFetching">
            <Icon name="loading_dots" class="w-10 text-primary" />
          </div>
          <div v-if="insights.length">
            <template v-for="(insight, index) in insights">
              <div v-if="isAI(insight)" :key="`ai-${index}`" v-html="aIInsightTypewriterHtml[index]"></div>
              <div v-else class="w-full mt-1" :key="index" v-html="insight"></div>
            </template>
          </div>
          <div v-else>{{ $t('conversation.no_insights') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardInsights',
  props: {
    insights: {
      type: [Array, Object, String],
    },
    show: Boolean,
    datasourceType: String,
  },
  data() {
    return {
      insightResponses: [],
      insightFetching: false,
      aIInsightTypewriterHtml: [],
    };
  },
  methods: {
    isAI(insight) {
      return typeof insight === 'object' && 'context' in insight;
    },
  },
};
</script>
