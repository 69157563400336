const len = (value) => {
  if (Array.isArray(value)) return value.length;
  if (typeof value === 'object') {
    return Object.keys(value).length;
  }
  return String(value).length;
};

export function string(value) {
  return typeof value === 'string' || value instanceof String;
}

export function email(value) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return string(value) && regex.test(value);
}

export function empty(value) {
  return string(value) && value.trim().length === 0;
}

export function integer(value) {
  const regex = /(^[0-9]*$)|(^-[0-9]+$)/;
  return regex.test(value);
}
export function numeric(value) {
  const regex = /^\d*(\.\d+)?$/;
  return regex.test(value);
}
export function password(value) {
  // eslint-disable-next-line
  const regex = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
  return regex.test(value);
}

export function minValue(value, min) {
  return value >= min;
}
export function maxValue(value, min) {
  return value <= min;
}

export function minLength(value, length) {
  return len(value) >= length;
}
export function maxLength(value, length) {
  return len(value) <= length;
}
export function same(...params) {
  if (params.length < 2) return true;
  const firstParam = params[0];
  return params.every((param) => param === firstParam);
}
