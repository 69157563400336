<template>
  <div class="pl-4 pr-4 pt-4 mb-5 md:pt-0 md:mb-10 md:pl-0 md:pr-10">
    <ValidationObserver v-slot="{ validate, errors }" class="w-full flex flex-col justify-start">
      <div class="mb-10">
        <div class="flex items-center">
          <Avatar size="15" :picture="picture" />

          <div class="ml-5">
            <div class="cursor-pointer hover:text-indigo-800" @click="openProfilePhotoModal">{{ $t('settings.change_profile_photo') }}</div>
            <div v-if="!!picture" class="cursor-pointer hover:text-indigo-800" @click="onRemovePhotoClick">{{ $t('remove') }}</div>
          </div>
        </div>
      </div>

      <FormInput name="name" rules="required" :error="errors['name']" class="mt-3" :label="$t('name')" v-model="name" @update:modelValue="dirty = true"></FormInput>
      <FormInput name="email" rules="required|email" :error="errors['email']" class="mt-3" :label="$t('email')" v-model="email" disabled></FormInput>

      <div class="w-full flex flex-wrap mt-3 -mb-3">
        <FormInput class="w-full sm:w-1/2 sm:pr-2 md:pr-0 md:w-full lg:w-1/2 lg:pr-2 mb-3" :label="$t('login.organisation')" v-model="organisation" @update:modelValue="dirty = true"></FormInput>
        <FormInput class="w-full sm:w-1/2 sm:pl-2 md:pl-0 md:w-full lg:w-1/2 lg:pl-2 mb-3" :label="$t('login.position')" v-model="position" @update:modelValue="dirty = true"></FormInput>
      </div>

      <div class="w-full flex flex-wrap mt-3 -mb-3">
        <div class="w-full sm:w-1/2 sm:pr-2 md:pr-0 md:w-full lg:w-1/2 lg:pr-2 mb-3">
          <FormSelect
            name="country"
            rules="required"
            :error="errors['country']"
            :label="$t('login.country')"
            :modelValue="country"
            :options="countries"
            :clearable="false"
            :reduce="(option) => option.value"
            @update:modelValue="onCountryChange"
          ></FormSelect>
        </div>

        <div class="w-full sm:w-1/2 sm:pl-2 md:pl-0 md:w-full lg:w-1/2 lg:pl-2 mb-3">
          <label class="text-12 text-gray-600 mb-1">{{ $t('timezone') }}</label>
          <v-select v-model:model-value="selectedTimeZone" class="w-full" :options="TIMEZONES" :clearable="false" @update:modelValue="dirty = true"></v-select>
        </div>
      </div>

      <div class="w-full flex flex-col mt-3">
        <label class="text-12 text-gray-600 mb-1">{{ $t('language') }}</label>
        <v-select :model-value="language" :options="languages()" :clearable="false" :reduce="(option) => option.value" @update:modelValue="onLanguageChange"></v-select>
      </div>

      <div class="flex justify-between items-center mt-8">
        <span v-if="canDeleteAccount" class="hover:underline text-gray-400 cursor-pointer mr-5" @click="openDeleteModal">{{ $t('delete_account') }}</span>
        <PillButton :loading="submitLoading" :text="$t('save')" class="min-w-100 ml-auto" primary :disabled="!dirty || submitLoading" @click="update(validate)" />
      </div>
    </ValidationObserver>
    <Modal v-if="showAccountDeleteModal" sheetbelowsm :title="$t('settings.delete_account_title')">
      <div class="px-10 w-full sm:w-700">
        <div class="mt-4 leading-24 text-alert font-500 capitalize">{{ $t('settings.delete_account_message_1', { appName }) }}</div>
        <div class="mt-3 mb-3 leading-24">{{ $t('settings.delete_account_message_2') }}</div>
        <div v-html="$t('settings.delete_account_message_3', { email: appEmail })"></div>
        <FormInput v-model="confirmText" :label="$t('settings.delete_account_input_label')" class="mt-5 mb-3"></FormInput>
      </div>
      <template #footer>
        <div class="w-full flex py-4 px-10">
          <PillButton :text="$t('cancel')" @click="showAccountDeleteModal = false" />
          <PillButton :loading="accountDeleting" :disabled="confirmText !== 'DELETE'" error :text="$t('delete_account')" class="ml-auto" @click="onDeleteClick" />
        </div>
      </template>
    </Modal>
    <Modal v-if="showProfileChangeModal" sheetbelowsm :title="$t('settings.select_profile_photo')">
      <div class="px-10 w-full sm:w-700 text-center mb-4">
        <div class="" @drop="onFileDrop" @dragover="allowDrop">
          <img v-show="!picturePreview" src="@/assets/png/drag_image.png" class="w-[150px] mx-auto" alt="" @click="onBrowseClick" />
          <ImageCropper v-if="picturePreview" ref="image-cropper" :image="picturePreview" />
          <div class="mb-3 leading-24">{{ $t('settings.supported_profile_photo_message') }}</div>
          <input type="file" name="profile_picture" ref="profilePictureInput" class="hidden" accept="image/png,image/jpg,image/jpeg" @change="onFileDrop" />
        </div>
        <div>
          <PillButton v-show="picturePreview" :text="$t('clear')" @click="picturePreview = null"></PillButton>
          <PillButton :text="$t('browse')" @click="onBrowseClick"></PillButton>
        </div>
      </div>
      <template #footer>
        <div class="w-full flex py-4 px-10">
          <PillButton :text="$t('cancel')" @click="showProfileChangeModal = false" />
          <PillButton primary :disabled="!picturePreview" :text="$t('settings.set_profile_picture')" class="ml-auto" @click="setProfilePicture" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { COUNTRIES, FEATURES, TIMEZONES } from '@/constants';
import { languages } from '@/plugins/i18n';
import { apiDeleteAccount } from '@/helpers/api';
import ImageCropper from '@/components/ui/ImageCropper.vue';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';

export default {
  name: 'Profile',
  components: { ValidationObserver, ImageCropper },
  data() {
    return {
      name: '',
      email: '',
      confirmText: '',
      picture: '',
      picturePreview: '',
      country: '',
      organisation: '',
      position: '',
      language: '',
      selectedTimeZone: null,
      showAccountDeleteModal: false,
      showProfileChangeModal: false,
      fileUploadError: '',
      TIMEZONES,
      dirty: false,
      accountDeleting: false,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      submitLoading: false,
    };
  },
  computed: {
    ...mapState(['user', 'timezone', 'bootstrapped', 'locale', 'features']),
    appName() {
      return process.env.VUE_APP_COMPANY;
    },
    appEmail() {
      return process.env.VUE_APP_SUPPORT_EMAIL;
    },
    canDeleteAccount() {
      return this.features.includes(FEATURES.SETTINGS_DELETE_ACCOUNT);
    },
  },
  methods: {
    ...mapActions(['deleteTenant', 'updateProfile', 'showToastMessage', 'changeLocale']),
    openProfilePhotoModal() {
      this.showProfileChangeModal = true;
    },
    onRemovePhotoClick() {
      this.picture = '';
      this.dirty = true;
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    processImage(file) {
      if (file.type.startsWith('image/') && /\.(jpg|jpeg|png)$/i.test(file.name)) {
        if (file.size <= 256 * 1024) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.picturePreview = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.showToastMessage({ title: this.$t('settings.file_size_limit_error'), type: 'error' });
        }
      } else {
        this.showToastMessage({ title: this.$t('settings.file_type_error'), type: 'error' });
      }
    },
    onFileDrop(ev) {
      this.fileUploadError = '';
      ev.stopPropagation();
      ev.preventDefault();

      if (ev?.dataTransfer?.items) {
        if (ev.dataTransfer.items.length > 0 && ev.dataTransfer.items[0].kind === 'file') {
          const file = ev.dataTransfer.items[0].getAsFile();
          this.processImage(file);
        }
      } else if (ev.target.files && ev.target.files.length) {
        const file = ev.target.files[0];
        this.processImage(file);
      }
    },
    onBrowseClick() {
      this.$refs.profilePictureInput.click();
    },
    async setProfilePicture() {
      this.dirty = true;
      const imagePreview = await this.$refs['image-cropper'].update();
      this.$refs.profilePictureInput.value = '';
      this.picturePreview = '';
      this.picture = imagePreview;
      this.showProfileChangeModal = false;
    },
    openDeleteModal() {
      this.showAccountDeleteModal = true;
      this.accountDeleting = false;
      this.confirmText = '';
    },
    async onDeleteClick() {
      this.accountDeleting = true;
      const response = await apiDeleteAccount();

      if (response.status === 401) {
        this.showToastMessage({ message: response.data.message || this.$t('settings.account_deleted'), type: 'success' });
      } else {
        this.accountDeleting = false;
        this.showToastMessage({ message: response.data.message || this.$t('settings.failed_to_delete_account'), type: 'error' });
      }
    },
    languages() {
      return languages.map((lang) => ({ label: this.$t(`languages_alt.${lang}`), value: lang }));
    },
    onLanguageChange(lang) {
      this.language = lang;
      this.dirty = true;
    },
    onCountryChange(country) {
      this.country = country;
      this.dirty = true;
    },
    async update(validate) {
      const valid = await validate();
      if (!valid.valid) {
        return;
      }
      try {
        this.submitLoading = true;
        const { name, picture, country, organisation, position, language } = this;
        const res = await this.updateProfile({ name, timezone: this.selectedTimeZone, picture, country, organisation, position, language });
        if (!res.error) {
          this.dirty = false;
          this.changeLocale(language);
          this.showToastMessage({ message: this.$t('settings.updated'), type: 'success' });
        } else {
          this.showToastMessage({ title: res.message || this.$t('settings.failed_to_update_account'), type: 'error' });
        }
        this.submitLoading = false;
        window.location.reload();
      } catch (e) {
        this.showToastMessage({ title: this.$t('settings.failed_to_update_account'), type: 'error' });
        this.submitLoading = false;
      }
    },
  },
  watch: {
    bootstrapped: {
      handler() {
        if (this.bootstrapped) {
          this.email = this.user.email;
          this.name = this.user.name;
          this.selectedTimeZone = this.timezone;
          this.picture = this.user.picture;
          this.country = this.user.country;
          this.organisation = this.user.organisation;
          this.position = this.user.position;
          this.language = this.locale;
        }
      },
      immediate: true,
    },
  },
};
</script>
