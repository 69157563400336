<template>
  <div :style="{ width: maxWidth, height: parseInt(maxWidth) > parseInt(maxHeight) ? maxHeight : maxWidth }" class="w-full overflow-hidden" :class="{ [heightClass]: true }">
    <apexchart v-if="render" ref="chart" :options="getChartOptions" :series="getSeries" width="100%" height="100%"></apexchart>
  </div>
</template>

<script>
import debounce from '@/helpers/debounce';

export default {
  name: 'ChartSelector',
  props: {
    report: Object,
    selectedChartType: String,
    hideLegend: Boolean,
    heightClass: {
      type: String,
      default: 'h-250',
    },
  },
  data() {
    return {
      render: false,
      maxWidth: undefined,
      maxHeight: undefined,
      resizeObserver: null,
    };
  },
  mounted() {
    if (!this.heightClass || !this.heightClass.length) {
      this.setSize();
      window.addEventListener('resize', this.onResize);

      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$el.parentElement);
    }
    this.render = true;
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  computed: {
    chartType() {
      return this.selectedChartType;
    },
    chart() {
      return this.report.chart.find((c) => c.chart.type.toLowerCase() === this.chartType);
    },
    getSeries() {
      return this.chart?.series || [];
    },
    getChartOptions() {
      if (!this.chart) {
        return {};
      }
      const chart = JSON.parse(JSON.stringify(this.chart));
      chart.chart.type = chart.chart.type.split('-')[0];
      if (chart?.yaxis?.labels?.formatter && typeof chart?.yaxis?.labels?.formatter === 'string') {
        chart.yaxis.labels.formatter = new Function(`return ${chart.yaxis.labels.formatter}`)(); // eslint-disable-line
      }
      if (Array.isArray(chart?.yaxis)) {
        chart.yaxis.forEach((yaxis) => {
          if (typeof yaxis?.labels?.formatter === 'string') {
            yaxis.labels.formatter = new Function(`return ${yaxis.labels.formatter}`)(); // eslint-disable-line
          }
        });
      }
      if (chart?.xaxis?.labels?.formatter && typeof chart?.xaxis?.labels?.formatter === 'string') {
        chart.xaxis.labels.formatter = new Function(`return ${chart.xaxis.labels.formatter}`)(); // eslint-disable-line
      }
      if (chart?.plotOptions?.bar?.dataLabels?.total?.formatter && typeof chart?.plotOptions?.bar?.dataLabels?.total?.formatter === 'string') {
        chart.plotOptions.bar.dataLabels.total.formatter = new Function(`return ${chart.plotOptions.bar.dataLabels.total.formatter}`)(); // eslint-disable-line
      }
      if (chart?.tooltip?.y?.formatter && typeof chart?.tooltip?.y?.formatter === 'string') {
        chart.tooltip.y.formatter = new Function(`return ${chart.tooltip.y.formatter}`)(); // eslint-disable-line
      }
      if (chart?.dataLabels?.formatter && typeof chart?.dataLabels?.formatter === 'string') {
        chart.dataLabels.formatter = new Function(`return ${chart.dataLabels.formatter}`)(); // eslint-disable-line
      }
      if (this.chartType === 'pie' || this.chartType === 'donut') {
        return {
          ...chart,
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, `${val.toFixed(0)}%`];
            },
          },
        };
      }
      // if (this.chartType === 'bar') {
      //   return {
      //     ...this.chart,
      // yaxis: {
      // labels: {
      //   formatter(val) {
      //     return val.toFixed(0);
      //   },
      // },
      // },
      // tooltip: {
      //   y: {
      //     formatter(val) {
      //       return val.toString();
      //     },
      //   },
      // },
      // };
      // }

      return chart;
    },
  },
  methods: {
    onResize() {
      debounce(this.setSize, 300)();
    },
    setSize() {
      if (this.$el.parentElement) {
        const parentWidth = this.$el.parentElement.getBoundingClientRect().width;
        const parentHeight = this.$el.parentElement.getBoundingClientRect().height;
        this.maxWidth = `${parentWidth * 0.99}px`;
        this.maxHeight = `${parentHeight * 0.99}px`;
      }
    },
  },
  watch: {
    selectedChartType() {
      this.render = false;
      this.$nextTick().then(() => {
        this.render = true;
        this.onResize();
      });
    },
  },
};
</script>
