<template>
  <div>
    <div v-show="imagePreview" ref="container" class="w-300 h-300 mx-auto outline-1 border flex items-center justify-center overflow-hidden relative select-none" @wheel="onScroll">
      <img v-if="imagePreview" :src="imagePreview" class="w-full h-full object-cover pointer-events-none" :draggable="false" alt="" />
      <div ref="holder" class="absolute" :style="{ width: `${holderSize}px`, height: `${holderSize}px` }">
        <div class="border border-dashed rounded-full w-full h-full absolute inset-0"></div>
      </div>
    </div>
    <div v-if="imagePreview" class="my-2 flex items-center justify-center">
      <input v-model="zoomLevel" type="range" :min="minZoomLevel" :max="maxZoomLevel" class="slider" id="myRange" @input="setHolderSize" />
    </div>

    <div class="hidden">
      <canvas width="250" height="250" class="outline rounded-full" id="canvas"></canvas>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImageCropper',
  props: {
    image: String,
  },
  data() {
    return {
      imageWidth: 300,
      holderSize: 200,
      imagePreview: null,
      zoomLevel: 15,
      zoomOffset: 10,
    };
  },
  computed: {
    maxZoomLevel() {
      return this.imageWidth / this.zoomOffset;
    },
    minZoomLevel() {
      return 6;
    },
  },
  mounted() {
    const draggableElement = this.$refs.holder;
    let offsetX;
    let offsetY;
    let isDragging = false;

    draggableElement.addEventListener('mousedown', (e) => {
      isDragging = true;

      const containerRect = this.$refs.holder.parentElement.getBoundingClientRect();
      const rect = this.$refs.holder.getBoundingClientRect();

      const left = rect.left - containerRect.left;
      const top = rect.top - containerRect.top;

      offsetX = e.clientX - left;
      offsetY = e.clientY - top;
      draggableElement.style.cursor = 'grabbing';
    });

    document.addEventListener('mousemove', (e) => {
      if (!isDragging) return;

      const x = e.clientX - offsetX;
      const y = e.clientY - offsetY;
      this.setHolderPosition(x, y);
    });

    document.addEventListener('mouseup', () => {
      isDragging = false;
      draggableElement.style.cursor = 'grab';
    });
  },
  methods: {
    setHolderPosition(x, y) {
      const draggableElement = this.$refs.holder;
      if (x < 0) {
        x = 0;
      }
      if (x + this.holderSize > this.imageWidth) {
        x = this.imageWidth - this.holderSize - 2;
      }
      if (y < 0) {
        y = 0;
      }
      if (y + this.holderSize > this.imageWidth) {
        y = this.imageWidth - this.holderSize - 2;
      }
      draggableElement.style.left = `${x}px`;
      draggableElement.style.top = `${y}px`;
    },
    async update() {
      const containerRect = this.$refs.holder.parentElement.getBoundingClientRect();
      const rect = this.$refs.holder.getBoundingClientRect();

      const left = rect.left - containerRect.left;
      const top = rect.top - containerRect.top;

      const { newWidth, newHeight } = await this.resizeImage(this.imagePreview, this.imageWidth, this.imageWidth);
      await this.cropImage(this.getCanvasData(), (newWidth - this.imageWidth) / 2, (newHeight - this.imageWidth) / 2, this.imageWidth, this.imageWidth);
      await this.cropImage(this.getCanvasData(), left, top, rect.width, rect.height);
      await this.resizeImage(this.getCanvasData(), 128, 128);
      return this.getCanvasData();
    },
    onScroll(e) {
      if (e.deltaY > 0) {
        this.zoomLevel--;
      } else {
        this.zoomLevel++;
      }
      this.setHolderSize();
    },
    setHolderSize() {
      if (this.zoomLevel < this.minZoomLevel) {
        this.zoomLevel = this.minZoomLevel;
      }
      if (this.zoomLevel > this.maxZoomLevel) {
        this.zoomLevel = this.maxZoomLevel;
      }
      this.holderSize = this.zoomLevel * this.zoomOffset - 2;

      const containerRect = this.$refs.holder.parentElement.getBoundingClientRect();
      const rect = this.$refs.holder.getBoundingClientRect();

      const x = rect.left - containerRect.left - 1;
      const y = rect.top - containerRect.top - 1;
      this.setHolderPosition(x, y);
    },
    getCanvasData() {
      return document.getElementById('canvas').toDataURL('image/jpeg', 1);
    },
    resizeImage(imagePath, newWidth, newHeight) {
      return new Promise((resolve) => {
        // create an image object from the path
        const originalImage = new Image();
        originalImage.src = imagePath;

        // get a reference to the canvas
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');

        // wait for the image to load
        originalImage.addEventListener('load', () => {
          // get the original image size and aspect ratio
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth / originalHeight;

          // if the new height wasn't specified, use the width and the original aspect ratio
          if (aspectRatio <= 1) {
            // calculate the new height
            newHeight = newWidth / aspectRatio;
            newHeight = Math.floor(newHeight);
          } else {
            // calculate the new width
            newWidth = newHeight * aspectRatio;
            newWidth = Math.floor(newWidth);
          }

          // set the canvas size
          canvas.width = newWidth;
          canvas.height = newHeight;

          // render the image
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);

          return resolve({ newWidth, newHeight });
        });
      });
    },
    cropImage(imagePath, newX, newY, newWidth, newHeight) {
      return new Promise((resolve) => {
        const originalImage = new Image();
        originalImage.src = imagePath;
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth / originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth / aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(originalImage, newX, newY, newWidth, newHeight, 0, 0, newWidth, newHeight);
          resolve();
        });
      });
    },
  },
  watch: {
    image: {
      handler() {
        this.imagePreview = this.image;
        this.zoomLevel = 15;
        this.$nextTick().then(() => {
          this.setHolderSize();
        });
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss"></style>
