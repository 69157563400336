<template>
  <div class="h-full flex flex-col items-center justify-start" style="background-color: #faf9ff">
    <div class="fixed inset-0 pointer-events-none">
      <div id="circle-top"></div>
      <div id="circle-bottom"></div>
    </div>

    <div class="z-0 w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultNoHeaderLayout',
};
</script>
