<template>
  <div class="subscription max-w-screen-xxl w-full sm:pt-10 pb-5">
    <div class="font-600 text-20 md:text-24 max-md:px-3">{{ $t('onboarding.select_subscription') }}</div>
    <div class="flex mt-3 md:mt-10 w-full relative">
      <template v-if="isMobile">
        <Swiper :loop="false" :spaceBetween="0" slidesPerView="auto" :space-between="10" centeredSlides :initialSlide="1">
          <SwiperSlide> <PlanItemFree :selected="selectedIndex === 1" @click="onPlanClick(1)" /></SwiperSlide>
          <SwiperSlide>
            <PlanItemPremium
              :selected="selectedIndex === 2"
              :period="premiumPeriod"
              :price-text="premiumPriceText"
              :periods="periods"
              @click="onPlanClick(2)"
              @onPeriodChange="onPremiumPeriodChange"
            />
          </SwiperSlide>
          <SwiperSlide>
            <PlanItemUnlimited
              :selected="selectedIndex === 3"
              :period="unlimitedPeriod"
              :price-text="unlimitedPriceText"
              :periods="periods"
              @click="onPlanClick(3)"
              @onPeriodChange="onUnlimitedPeriodChange"
            />
          </SwiperSlide>
        </Swiper>
      </template>
      <div v-else v-show="!paymentView" class="max-md:px-3 flex max-md:flex-wrap w-full">
        <PlanItemFree class="w-full md:w-1/3 md:mr-2" :selected="selectedIndex === 1" @click="onPlanClick(1)" />
        <PlanItemPremium
          class="w-full md:w-1/3 md:mx-2"
          :selected="selectedIndex === 2"
          :period="premiumPeriod"
          :price-text="premiumPriceText"
          :periods="periods"
          @click="onPlanClick(2)"
          @onPeriodChange="onPremiumPeriodChange"
        />
        <PlanItemUnlimited
          class="w-full md:w-1/3 md:ml-2"
          :selected="selectedIndex === 3"
          :period="unlimitedPeriod"
          :price-text="unlimitedPriceText"
          :periods="periods"
          @click="onPlanClick(3)"
          @onPeriodChange="onUnlimitedPeriodChange"
        />
      </div>

      <div v-if="paymentView" class="max-md:px-3 shadow-card w-full max-md:mt-3 rounded-10 bg-white relative pb-5">
        <div class="flex items-center px-10 py-3">
          <img :src="logo" class="w-10 md:w-10" alt="" />
          <div class="text-16 font-600 ml-4">{{ selectedPlanName }}</div>
          <div class="ml-auto">
            <div class="flex justify-end items-center">
              <div class="text-20 font-500">
                {{ paymentPriceText }}
              </div>
              <div class="ml-2 text-13">({{ billedPeriodText }})</div>
            </div>
            <div v-if="discountApplied" class="text-11 text-gray-600">
              <span v-if="isAnnual">{{ $t('subscription.after_discount_yearly', { price: planPriceText }) }}</span>
              <span v-else>{{ $t('subscription.after_discount_monthly', { price: planPriceText }) }}</span>
            </div>
          </div>
        </div>
        <Divider dense />
        <div class="px-3 md:px-10 w-full">
          <div class="paddle-checkout-container flex-grow w-full flex-shrink-0 pl-2" style="min-height: 510px"></div>
        </div>

        <div v-if="paymentSucceed" class="absolute w-full mt-15 inset-0 bg-white rounded-10">
          <div class="flex-grow flex items-center justify-center h-full">
            <Spinner class="w-8" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 flex justify-between max-md:px-3">
      <div class="text-14">
        By subscribing you accept to our <span class="text-tertiary cursor-pointer" @click="showTermsConditions = true">Terms & Conditions</span> and
        <span class="text-tertiary cursor-pointer" @click="showPrivacyPolicy = true">Privacy Policy</span>.
      </div>
      <PillButton v-if="paymentView && !paymentSucceed" :disabled="paymentProcessing" :text="$t('onboarding.previous')" class="ml-auto" @click="back"></PillButton>
      <PillButton v-if="!paymentView" :disabled="!selectedIndex" tertiary :text="$t('login.continue')" class="ml-3" @click="next"></PillButton>
    </div>

    <Modal v-if="showTermsConditions" title="Terms & Conditions" sheetbelowsm overflow-container-class="w-full sm:w-800 h-600" close-button @close="showTermsConditions = false">
      <iframe width="100%" height="100%" :src="termsConditionsUrl" />
    </Modal>
    <Modal v-if="showPrivacyPolicy" title="Privacy Policy" sheetbelowsm overflow-container-class="w-full sm:w-800 h-600" close-button @close="showPrivacyPolicy = false">
      <iframe width="100%" height="100%" :src="privacyPolicyUrl" />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PaddleController from '@/helpers/paddle';
import delay from '@/helpers/delay';
import { formatPrice } from '@/helpers/pricing';
import { SUBSCRIPTION_STATES } from '@/constants';
import PlanItemFree from '@/components/onboarding/PlanItemFree.vue';
import PlanItemPremium from '@/components/onboarding/PlanItemPremium.vue';
import PlanItemUnlimited from '@/components/onboarding/PlanItemUnlimited.vue';
import Swiper from '@/components/ui/Swiper.vue';
import SwiperSlide from '@/components/ui/SwiperSlide.vue';

const { PREMIUM_MONTHLY, PREMIUM_ANNUAL, UNLIMITED_ANNUAL, UNLIMITED_MONTHLY } = PaddleController.productId;

export default {
  name: 'Subscription',
  components: { SwiperSlide, Swiper, PlanItemUnlimited, PlanItemPremium, PlanItemFree },
  data() {
    return {
      isMobile: false,
      selectedIndex: 2,
      premiumPeriod: 'monthly',
      unlimitedPeriod: 'monthly',
      currency: '',
      prices: {
        [PREMIUM_MONTHLY]: null,
        [PREMIUM_ANNUAL]: null,
        [UNLIMITED_MONTHLY]: null,
        [UNLIMITED_ANNUAL]: null,
      },
      paymentView: false,
      paymentSucceed: false,
      paymentProcessing: false,
      discountApplied: false,
      checkoutTotal: null,
      selectedPlanId: PREMIUM_MONTHLY,
      planNames: {
        [PREMIUM_MONTHLY]: 'Premium Monthly',
        [PREMIUM_ANNUAL]: 'Premium Annual',
        [UNLIMITED_MONTHLY]: 'Unlimited Monthly',
        [UNLIMITED_ANNUAL]: 'Unlimited Annual',
      },
      showTermsConditions: false,
      showPrivacyPolicy: false,
    };
  },
  created() {
    PaddleController.on('checkout.discount.applied', this.onCouponApply);
    PaddleController.on('checkout.discount.removed', this.onCouponRemove);
    PaddleController.on('checkout.loaded', this.onCheckoutLoad);
    PaddleController.on('checkout.completed', this.onCheckoutComplete);
    PaddleController.on('checkout.payment.initiated', this.onPaymentInitiated);
    PaddleController.on('checkout.payment.failed', this.onPaymentFailed);
    this.getPrices();

    if (window.innerWidth < 768) {
      this.isMobile = true;
    }

    if (this.subscription.status === SUBSCRIPTION_STATES.ACTIVE && this.subscription.plan !== 'FREE') {
      this.$emit('next');
    }
  },
  computed: {
    ...mapState(['user', 'tenantId', 'subscription', 'environment']),
    periods() {
      return [
        { label: this.$t('subscription.billed_monthly'), value: 'monthly' },
        { label: this.$t('subscription.billed_annual'), value: 'annual' },
      ];
    },
    privacyPolicyUrl() {
      return this.environment === 'dev' ? 'https://fidsy-website.web.app/privacy-policy' : 'https://fidsy.com/privacy-policy';
    },
    termsConditionsUrl() {
      return this.environment === 'dev' ? 'https://fidsy-website.web.app/terms-of-use' : 'https://fidsy.com/terms-of-use';
    },
    selectedPlanName() {
      return this.planNames?.[this.selectedPlanId] || '';
    },
    paymentPriceText() {
      return this.checkoutTotal || '';
    },
    planPriceText() {
      return this.prices[this.selectedPlanId] || '';
    },
    billedPeriodText() {
      const period = {
        [PREMIUM_MONTHLY]: this.$t('subscription.billed_monthly'),
        [PREMIUM_ANNUAL]: this.$t('subscription.billed_annual'),
        [UNLIMITED_MONTHLY]: this.$t('subscription.billed_monthly'),
        [UNLIMITED_ANNUAL]: this.$t('subscription.billed_annual'),
      };
      return period?.[this.selectedPlanId];
    },
    premiumPriceText() {
      if (this.premiumPeriod === 'monthly') {
        return this.prices[PREMIUM_MONTHLY];
      }
      return this.prices[PREMIUM_ANNUAL];
    },
    unlimitedPriceText() {
      if (this.unlimitedPeriod === 'monthly') {
        return this.prices[UNLIMITED_MONTHLY];
      }
      return this.prices[UNLIMITED_ANNUAL];
    },
    logo() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
    isAnnual() {
      return this.selectedPlanId === PREMIUM_ANNUAL || this.selectedPlanId === UNLIMITED_ANNUAL;
    },
  },
  methods: {
    ...mapActions(['getTenant']),
    onPremiumPeriodChange(value) {
      this.premiumPeriod = value;

      if (value === 'monthly') {
        this.selectedPlanId = PREMIUM_MONTHLY;
      } else {
        this.selectedPlanId = PREMIUM_ANNUAL;
      }
    },
    onUnlimitedPeriodChange(value) {
      this.unlimitedPeriod = value;
      if (value === 'monthly') {
        this.selectedPlanId = UNLIMITED_MONTHLY;
      } else {
        this.selectedPlanId = UNLIMITED_ANNUAL;
      }
    },
    onPlanClick(index) {
      this.selectedIndex = index;

      if (this.selectedIndex === 2) {
        this.selectedPlanId = this.premiumPeriod === 'monthly' ? PREMIUM_MONTHLY : PREMIUM_ANNUAL;
      }
      if (this.selectedIndex === 3) {
        this.selectedPlanId = this.unlimitedPeriod === 'monthly' ? UNLIMITED_MONTHLY : UNLIMITED_ANNUAL;
      }
    },
    back() {
      this.paymentView = false;
    },
    async next() {
      if (this.selectedIndex === 1) {
        this.$emit('free');
      } else if (this.selectedIndex === 2 || this.selectedIndex === 3) {
        this.paymentView = true;
        this.checkoutTotal = this.prices[this.selectedPlanId];

        await this.$nextTick();
        PaddleController.openCheckout({
          email: this.user.email,
          addressId: this.userAddressId,
          product: this.selectedPlanId,
          customData: {
            tenant_id: this.tenantId,
            user_id: this.user.id,
          },
        });
      }
    },
    onCouponApply(data) {
      this.discountApplied = true;
      this.updatePrice(data);
    },
    onCouponRemove(data) {
      this.discountApplied = false;
      this.updatePrice(data);
    },
    onCheckoutLoad(data) {
      this.updatePrice(data);
    },
    async onCheckoutComplete() {
      this.paymentSucceed = true;
      await this.checkTenantSubscriptionStatus();
      this.$emit('next');
    },
    onPaymentInitiated() {
      this.paymentProcessing = true;
    },
    onPaymentFailed() {
      this.paymentProcessing = false;
    },
    updatePrice(event) {
      const { data } = event;
      const { currency_code } = data;
      const { total } = data.totals;
      this.checkoutTotal = formatPrice(total, currency_code);
    },
    getPrices() {
      PaddleController.getPrices().then((res) => {
        const { data } = res;
        this.currency = data.currencyCode;
        const items = data.details.lineItems;

        items.forEach((item) => {
          this.prices[item.price.id] = item.formattedTotals.total;
        });
      });
    },
    async checkTenantSubscriptionStatus() {
      if (this.subscription.plan !== this.selectedPlanId) {
        await delay();
        await this.getTenant();
        await this.checkTenantSubscriptionStatus();
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
.subscription {
  .swiper {
    width: calc(100vw - 4px);
    height: 100%;
    padding-top: 3px;
  }

  .swiper-slide {
    height: auto;
    width: 80%;
    > div {
      height: calc(100% - 32px);
      margin-left: auto;
      margin-right: auto;
      width: 75vw;
      margin-bottom: 24px;
    }
  }
}
</style>
