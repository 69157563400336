<template>
  <button
    @click="!disabled && $emit('click', $event)"
    @blur="$emit('blur', $event)"
    class="rounded-full focus:outline-none whitespace-nowrap text-13 font-500 truncate flex-shrink-0 select-none"
    :class="{
      'h-7 py-1 px-4': small,
      'h-8 py-1 px-4': !small,
      'flex items-center justify-center': loading || icon || trailingIcon,
      'bg-white hover:bg-gray-200': !disabled && !primary && !secondary && !success && !outlined && !tertiary && !error,
      'bg-primary text-on-primary hover:text-on-primary-hover hover:bg-primary-hover': !disabled && primary,
      'bg-secondary text-on-secondary': !disabled && secondary,
      'bg-tertiary text-on-tertiary hover:opacity-90': !disabled && tertiary,
      'bg-green-500 text-white hover:bg-green-600': !disabled && success,
      'bg-red-500 text-white hover:bg-red-600 hover:text-white': !disabled && error,
      'shadow-analysisCard': !disabled && elevated,
      'border border-px border-solid border-gray-200 hover:bg-gray-200': outlined,
      'bg-gray-200 text-gray-500 cursor-not-allowed': disabled,
    }"
  >
    <Icon
      v-if="icon || loading"
      :name="loading ? 'loading_circle' : icon"
      class="w-4 h-4 flex-shrink-0"
      :class="{
        'animate-spin': loading,
        [iconClass]: true,
        'text-gray-500': !disabled && !primary && !secondary && !tertiary && !success && !error && !outlined,
        'text-on-secondary': !disabled && secondary,
        'text-on-tertiary': !disabled && tertiary,
        'text-white': !disabled && success,
        'text-white': !disabled && error,
        'mr-2': !hideTextOnLoading,
      }"
    ></Icon>
    <span class="text-left" :class="{ [textClass]: true }" style="text-overflow: inherit; overflow: inherit">
      {{ hideTextOnLoading && loading ? '' : text }}
    </span>
    <Icon
      v-if="trailingIcon"
      :name="loading ? 'loading_circle' : trailingIcon"
      class="w-4 h-4 flex-shrink-0"
      :class="{
        'animate-spin': loading,
        [iconClass]: true,
        'text-gray-500': !disabled && !primary && !secondary && !tertiary && !success && !error && !outlined,
        'text-white': !disabled && secondary,
        'text-white': !disabled && tertiary,
        'text-white': !disabled && success,
        'text-white': !disabled && error,
        'ml-1 -mr-2': !hideTextOnLoading,
      }"
    ></Icon>
  </button>
</template>

<script>
export default {
  name: 'PillButton',
  props: {
    text: String,
    icon: String,
    trailingIcon: String,
    iconClass: String,
    textClass: String,
    primary: Boolean,
    secondary: Boolean,
    tertiary: Boolean,
    disabled: Boolean,
    success: Boolean,
    elevated: Boolean,
    error: Boolean,
    outlined: Boolean,
    small: Boolean,
    loading: Boolean,
    hideTextOnLoading: Boolean,
  },
  emits: ['click', 'blur'],
};
</script>
