<template>
  <div>
    <div
      class="relative border-b-4 transition-all ease-in duration-150"
      :class="{ 'border-alert': !!error, 'border-transparent': !error, 'focus-within:border-primary': !light, 'focus-within:border-white': light }"
    >
      <input
        v-model="modelValue"
        :name="name"
        :disabled="disabled"
        :type="getType"
        placeholder=" "
        spellcheck="false"
        :data-light="light"
        class="block p-4 pb-0 w-full text-16 z-10 border-b appearance-none focus:outline-none bg-transparent"
        :class="{ 'border-gray-300': !error, 'border-transparent': error }"
        @keyup.stop="$emit('keyup', $event)"
      />
      <label v-if="label" class="absolute top-0 text-16 p-4 duration-300 origin-0 pointer-events-none text-gray-800">{{ label }}</label>
      <Icon
        v-if="type === 'password' && showPasswordEye"
        :name="showPassword ? 'visibility_off' : 'visibility'"
        class="absolute bottom-1 z-10 right-2 w-4 h-4 cursor-pointer text-gray-600"
        @click="onShowPasswordClick"
      ></Icon>
    </div>
    <div v-if="error" class="inline-flex mt-2 ml-5 rounded-r-full rounded-bl-full bg-alert px-5 py-2 text-[14px] text-white">{{ error }}</div>
  </div>
</template>
<script>
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  name: 'MaterialInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    value: {
      type: String,
      default: '',
    },
    name: String,
    label: String,
    light: Boolean,
    error: String,
    showPasswordEye: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [ValidationMixin],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    getType() {
      return this.type === 'password' && this.showPassword ? 'text' : this.type;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onShowPasswordClick() {
      this.showPassword = !this.showPassword;
      this.$emit('show-password', this.showPassword);
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-6 translate-x-1;
}

input:focus-within ~ label {
  @apply text-primary;
}

input[data-light='true']:focus-within ~ label {
  color: #e0d1ff;
}
</style>
