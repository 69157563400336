<template>
  <div :class="{ 'datatable--responsive': responsive }" class="w-full min-h-10 flex flex-col border sm:rounded-lg border-b border-gray-200" :style="{ height: `${height}px` }">
    <div class="overflow-auto">
      <table class="divide-y divide-gray-200 min-w-full-1 db">
        <thead>
          <tr>
            <th
              scope="col"
              v-for="(column, index) in columnNames"
              :key="index"
              class="sm:px-4 text-left font-400 tracking-wider"
              :class="{ 'border-r border-gray-200': index < columnNames.length - 1, 'px-2 py-3': !dense, 'px-1 py-1': dense }"
            >
              <div class="flex flex-row items-center justify-between cursor-pointer" @click="sortColumn(column)">
                <span class="text-13 font-700 whitespace-nowrap">{{ convertColumnName(column) }}</span>
                <span v-if="columnsRaw.includes(`Previous_${column}`)" class="ml-1 text-12 text-gray-500">(Prev)</span>

                <div class="flex flex-col">
                  <Icon name="expand_less" class="w-4 h-4 ml-1 -mb-1" :class="{ 'opacity-25': sortColumnName === column && sortType === 'DESC' }" />
                  <Icon name="expand_more" class="w-4 h-4 ml-1 -mt-1" :class="{ 'opacity-25': sortColumnName === column && sortType === 'ASC' }" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white rounded-8">
          <tr v-for="(row, idx) in rows" :key="idx" :class="{ 'border-b border-gray-200': idx < rows.length + 1 }">
            <td
              class="sm:px-4 whitespace-nowrap text-14 border-gray-200"
              :class="{ 'border-r': index < row.length + 1, 'px-2 py-2': !dense, 'px-0.5 py-0.5': dense }"
              v-for="(column, index) in columnNames"
              :data-title="convertColumnName(column)"
              :key="`${column}_${index}`"
            >
              <div class="flex flex-wrap" :class="{ 'justify-end': columnDataTypes[column] === 'number' }">
                <div class="flex flex-row items-end">
                  <span v-if="displayPrevious(row, column)" class="ml-1 text-12 text-gray-500">({{ getPrevious(row, column) }})</span>
                  <span class="text-13">{{ getValue(row, column).toString() }}</span>
                </div>
                <div
                  v-if="row[`${column}__change`] || row[`${column}__change`] === 0"
                  :class="{
                    'text-green-100': row[`${column}__change`] > 0,
                    'text-red-100': row[`${column}__change`] < 0,
                    'text-gray-100': row[`${column}__change`] === 0 || row[`${column}__change`] === '∞',
                  }"
                  class="pl-1"
                >
                  <i class="text-12">
                    {{ row[`${column}__change`] }}%
                    <span v-if="row[`${column}__change`] > 0">▲</span>
                    <span v-if="row[`${column}__change`] < 0">▼</span>
                    <span v-if="row[`${column}__change`] === 0">-</span>
                  </i>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="totals.length && responsive" class="border-b border-gray-200 bg-gray-100">
            <td :data-title="$t('total')" class="px-2 sm:px-4 py-1 whitespace-nowrap text-14 border-gray-200">
              <div class="flex flex-row items-end">
                <span class="text-13 text-black font-700">{{ totals[1].value === '' || totals[1].value === 'undefined' ? $t('total') : getTotal(1, totals[1].value, totals[1].columnName) }}</span>
                <span v-if="totals[1].sub && totals[1].sub.value" class="ml-1 text-12 text-gray-500">({{ totals[1].sub.value }})</span>
              </div>
            </td>
          </tr>
          <tr v-if="totals.length && !responsive" class="border-b border-gray-200 bg-gray-100">
            <td
              :data-title="index === 0 ? $t('total') : undefined"
              class="px-2 sm:px-4 py-1 whitespace-nowrap text-14 border-gray-200"
              :class="{ 'border-r': index < totals.length + 1 }"
              v-for="(cell, index) in totals"
              :key="index"
            >
              <div class="flex" :class="{ 'justify-end': columnDataTypes[cell.columnName] === 'number' }">
                <div class="flex flex-row items-end">
                  <span class="text-13 text-black font-700">{{ getTotal(index, cell.value, cell.columnName) }}</span>
                  <span v-if="cell.sub && cell.sub.value" class="ml-1 text-12 text-gray-500">({{ cell.sub.value }})</span>
                </div>
                <div v-if="cell.value !== '' && cell.value !== 'undefined' && cell.change" class="pl-1">
                  <b>
                    <g class="text-12" v-if="cell.change > 0">{{ cell.change }}% ▲</g>
                    <r class="text-12" v-if="cell.change < 0">{{ cell.change }}% ▼</r>
                    <span class="text-12" v-if="cell.change === 0">{{ cell.change }}% ▼</span>
                    <span class="text-12" v-if="cell.change === '∞'">∞%</span>
                  </b>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="tableData.length" class="flex justify-between items-center py-1 px-3">
      <ButtonToggle v-if="showPercentToggle" v-model="percentToggle" size="small" :items="['123', '%']" />
      <span class="ml-auto text-12">{{ $t('items') }}: {{ tableData.length }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from '@/helpers/debounce';
import { integer, numeric } from '@/helpers/validators';

export default {
  name: 'DataTable',
  props: {
    table: Array,
    height: [Number, String],
    dense: Boolean,
    responsiveBreakpoint: {
      type: Number,
      default: 442,
    },
  },
  data() {
    return {
      display: 5,
      percentToggle: '123',
      responsive: false,
      resizeObserver: null,
      sortColumnName: null,
      sortType: null,
    };
  },
  created() {
    if (this.tableData.length < 2) {
      this.responsive = true;
    }
  },
  mounted() {
    this.setResponsive();
    window.addEventListener('resize', this.onResize, true);

    this.resizeObserver = new ResizeObserver(this.setResponsive);
    this.resizeObserver.observe(this.$el.parentElement);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize, true);
    this.resizeObserver.disconnect();
  },
  computed: {
    ...mapState(['locale']),
    tableData() {
      const table = [...this.table];
      if (this.sortColumnName) {
        if (this.sortType === 'ASC') {
          if (this.columnDataTypes[this.sortColumnName] === 'number') {
            table.sort((a, b) => a[this.sortColumnName] - b[this.sortColumnName]);
          } else {
            table.sort((a, b) => a[this.sortColumnName].localeCompare(b[this.sortColumnName]));
          }
        }
        if (this.sortType === 'DESC') {
          if (this.columnDataTypes[this.sortColumnName] === 'number') {
            table.sort((a, b) => b[this.sortColumnName] - a[this.sortColumnName]);
          } else {
            table.sort((a, b) => b[this.sortColumnName].localeCompare(a[this.sortColumnName]));
          }
        }
      }
      return table;
    },
    firstRow() {
      return this.table[0];
    },
    showPercentToggle() {
      if (this.table.length === 1) return false;
      return Object.keys(this.firstRow)
        .map((item) => item.trim())
        .some((columnName) => columnName.endsWith('__%'));
    },
    columnDataTypes() {
      const firstRow = { ...this.table[0] };
      Object.keys(firstRow).forEach((key) => {
        const data = this.table.map((i) => i[key]);
        firstRow[key] = this.getDataType(data);
      });
      return firstRow;
    },
    totals() {
      if (this.tableData.length < 2) {
        return [];
      }
      let totals = [];
      this.columnNames.forEach((columnName) => {
        let sum = '';
        let sumPrev = '';
        if (this.columnDataTypes[columnName] === 'number') {
          sum = this.tableData.reduce((acc, obj) => {
            let val = parseFloat(obj[columnName]);
            val = Number.isNaN(val) ? 0 : val;
            return acc + val;
          }, 0);

          sum = sum.toString().includes('.') ? sum.toFixed(2) : sum;

          if (this.columnsRaw.includes(`Previous_${columnName}`)) {
            sumPrev = this.tableData.reduce((acc, obj) => {
              let val = parseFloat(obj[`Previous_${columnName}`]);
              val = Number.isNaN(val) ? 0 : val;
              return acc + val;
            }, 0);
            sumPrev = sumPrev.toString().includes('.') ? sumPrev.toFixed(2) : sum;

            totals.push({ value: sum, change: this.calculatePercentageDiff(sum, sumPrev), sub: { value: sumPrev } });
            return;
          }
        }
        totals.push({ value: sum, change: '', sub: { value: '' }, columnName });
      });

      if (totals.every((item) => !item.value)) {
        totals = [];
      }

      return totals;
    },
    columnsRaw() {
      const [firstRow] = this.tableData;
      return Object.keys(firstRow).map((item) => item.trim());
    },
    columnNames() {
      const [firstRow] = this.tableData;
      return Object.keys(firstRow)
        .filter((c) => !c.endsWith('__%') && !c.endsWith('__change') && !c.startsWith('Previous_'))
        .map((item) => item.trim());
    },
    displayPercent() {
      return this.percentToggle === '%';
    },
    rows() {
      return this.tableData;
    },
  },
  methods: {
    // eslint-disable-next-line
    onResize: debounce(function () {
      this.setResponsive();
    }, 300),
    setResponsive() {
      if (this.tableData.length < 2) {
        this.responsive = true;
      } else if (this.columnNames.length < 3) {
        this.responsive = false;
      } else {
        this.responsive = this.$el.getBoundingClientRect().width < this.responsiveBreakpoint;
      }
    },
    getDataType(data) {
      if (data.every((i) => this.isNumeric(i))) {
        return 'number';
      }
      return 'string';
    },
    sortColumn(column) {
      if (!this.sortColumnName || this.sortColumnName === column) {
        if (!this.sortType) {
          this.sortType = 'ASC';
          this.sortColumnName = column;
        } else if (this.sortType === 'ASC') {
          this.sortType = 'DESC';
          this.sortColumnName = column;
        } else {
          this.sortType = null;
          this.sortColumnName = null;
        }
      } else {
        this.sortColumnName = column;
      }
    },
    convertColumnName(column) {
      return column.replaceAll('_', ' ').charAt(0).toUpperCase() + column.slice(1);
    },
    getPrevious(row, column) {
      return this.displayPercent && row[`Previous_${column}__%`] > -1 ? `${row[`Previous_${column}__%`]}%` : row[`Previous_${column}`];
    },
    displayPrevious(row, column) {
      return `Previous_${column}__%` in row || `Previous_${column}` in row;
    },
    calculatePercentageDiff(dividend, divisor) {
      if (dividend === 0 && divisor === 0) return 0;
      if (dividend > 0 && divisor === 0) return '∞';
      if (!Number.isFinite(Math.floor((100 * (dividend - divisor)) / divisor))) return '∞';
      return Math.floor((100 * (dividend - divisor)) / divisor);
    },
    getColumnDigit(column) {
      let digit = 0;
      const columnData = this.tableData.map((r) => r[column]);
      columnData.forEach((value) => {
        if (this.isStrNumeric(value)) {
          // eslint-disable-next-line
          const [left, right] = value.split('.');
          if (right && right.length > digit) digit = right.length;
        } else if (typeof value === 'number') {
          // eslint-disable-next-line
          const [left, right] = value.toString().split('.');
          if (right && right.length > digit) digit = right.length;
        }
      });
      return digit;
    },
    getValue(row, column) {
      if (this.displayPercent && row[`${column}__%`] > -1) {
        return `${row[`${column}__%`]}%`;
      }
      if (column.toLowerCase() === 'year' || column.toLowerCase() === this.$t('year').toLowerCase()) {
        return row[column];
      }
      if (this.isNumeric(row[column])) {
        return this.numericFormatter(row[column], this.locale, this.getColumnDigit(column));
      }
      return row[column];
    },
    isStrNumeric(str) {
      if (typeof str !== 'string') return false;
      return numeric(str) || integer(str);
    },
    isNumeric(str) {
      return this.isStrNumeric(str) || typeof str === 'number';
    },
    numericFormatter(val, locale, digit) {
      if (typeof val === 'number') return val.toLocaleString(locale, { minimumFractionDigits: digit });
      if (this.isStrNumeric(val)) return parseFloat(val).toLocaleString(locale, { minimumFractionDigits: digit });
      return val;
    },
    getTotal(index, value, columnName) {
      if (index === 0) return this.$t('total');
      if (value === '') return value;
      return this.isNumeric(value) ? this.numericFormatter(value, this.locale, this.getColumnDigit(columnName)) : value;
    },
  },
};
</script>
<style scoped lang="scss">
.datatable--responsive {
  border: none;

  table {
    border: none;
  }
  table,
  table tr,
  table td,
  table tbody,
  table thead {
    display: block;
  }
  table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table tbody tr {
    border: 1px solid #ebebec;
    border-radius: 1px;

    &:first-child {
      border-top: none;
    }
  }
  table tbody tr {
    border-bottom: none;
  }
  table td:before {
    content: attr(data-title);
    width: 45%;
    flex-shrink: 0;
    padding-right: 5px;
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    text-wrap: wrap;
  }

  table tr:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  table tr:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  table td {
    flex-shrink: 0;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-right: 2px;
    padding-left: 4px;
    white-space: normal;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
}
</style>
