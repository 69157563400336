<script>
import { defineComponent } from 'vue';
import VSelect from 'vue-select';
import OpenIndicator from './VSelectOpenIndicator';

export default defineComponent({
  extends: VSelect,
  // functional: false,
  // ...VSelect,
  emits: ['input', 'clear', 'update:modelValue'],
  props: {
    ...VSelect.props,
    autoSelect: {
      type: Boolean,
      default: false,
    },
    alternativeIndicator: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    VSelect.created.bind(this);

    this.autoSelectItem();
  },
  computed: {
    ...VSelect.computed,
    childComponents() {
      if (this.alternativeIndicator) {
        return {
          ...VSelect.computed.childComponents(),
          OpenIndicator,
        };
      }
      return VSelect.computed.childComponents();
    },
    optionsLength() {
      return this.options.length;
    },
  },
  mounted() {
    if (VSelect.mounted) {
      VSelect.mounted.bind(this);
    }
    if (this.alternativeIndicator) {
      this.$el.setAttribute('alternative-indicator', 'true');
    }
  },
  methods: {
    ...VSelect.methods,
    autoSelectItem() {
      if (this.autoSelect && this.options.length === 1) {
        const firstItem = this.options[0];
        if (typeof firstItem === 'string') {
          this.$emit('update:modelValue', firstItem);
          // this.$emit('option:selected', firstItem);
        } else if (typeof firstItem === 'object' && firstItem?.value !== undefined) {
          this.$emit('update:modelValue', firstItem.value);
          // this.$emit('option:selected', firstItem);
        }
      }
    },
    clearSelection() {
      this.updateValue(this.multiple ? [] : null);
      this.$emit('clear', this.multiple ? [] : null);
    },
  },
  watch: {
    ...VSelect.watch,
    selectedValue: {
      handler(newValue) {
        if (this.$el) {
          if (newValue.length) {
            this.$el.setAttribute('data-selected', 'true');
          } else {
            this.$el.setAttribute('data-selected', 'false');
          }
        }
      },
      deep: true,
      immediate: true,
    },
    alternativeIndicator: {
      handler(newValue) {
        if (this.$el) {
          if (newValue) {
            this.$el.setAttribute('alternative-indicator', 'true');
          } else {
            this.$el.setAttribute('alternative-indicator', 'false');
          }
        }
      },
      immediate: true,
    },
    optionsLength: {
      handler() {
        this.autoSelectItem();
      },
      // immediate: true,
    },
  },
});
</script>

<style lang="scss">
.v-select {
  --vs-controls-size: 0.8;
  --vs-dropdown-option--active-bg: var(--color-secondary);

  .vs__dropdown-toggle {
    background: white;
  }
}

.v-select[transparent] {
  .vs__dropdown-toggle {
    background: transparent;
  }
}

.v-select[dense] {
  .vs__selected-options {
    padding: 1px 2px 0 6px;
  }
  .vs__dropdown-toggle {
    //padding: 0 0 3px;
  }
}

.v-select[rounded] {
  .vs__dropdown-toggle {
    padding: 0 6px 4px 8px;
  }
}

.v-select[elevated] {
  .vs__dropdown-toggle {
    @apply shadow-analysisCard;
  }
}

.v-select[dense].v-select[elevated] {
  .vs__selected {
    font-size: 13px;
    --vs-line-height: 1.42;
    font-weight: 500;
  }
  .vs__search {
    font-size: 13px;
  }
}

.v-select[rounded] {
  --vs-border-radius: 20px;
  --vs-border-color: transparent;

  &[alternative-indicator='true'] {
    .vs__open-indicator {
      fill: none;
      transition: none;
      --vs-controls-size: 1;
    }
  }
}

.v-select[outlined] {
  --vs-border-radius: 20px;
  --vs-border-color: #dcd8ee;
}
</style>
