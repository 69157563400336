<template>
  <div class="w-full flex-col">
    <div class="w-full py-2 cursor-pointer flex items-center" :class="{ 'font-700': headerStrong }" @click.stop="toggle">
      <div class="flex-grow">
        <slot name="header" />
      </div>
      <Icon :class="{ 'rotate-90': open }" name="chevron_right" class="transform transition-all duration-300 ease-in-out w-5" />
    </div>
    <div class="w-full overflow-hidden relative transition-all duration-300 ease-in-out max-h-0" :class="{ 'max-h-500': open, 'opacity-0': !open, 'opacity-100': open }">
      <div class="w-full transition-all duration-300 ease-in-out pt-1 pb-2" :style="{ transform: open ? 'translateY(0)' : 'translateY(-100%)' }">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseMenuBorderless',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
    },
    headerStrong: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
      this.$emit('input', this.open);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue !== this.open) {
          this.open = this.value;
        }
      },
      immediate: true,
    },
  },
};
</script>
