<template>
  <div>
    <FadeTransition>
      <div v-if="showSidePanel" class="bg-black opacity-50 fixed inset-0" @click="hidePanel"></div>
    </FadeTransition>
    <div
      style="background-color: #faf9ff"
      :class="{ 'translate-x-full': !showSidePanel }"
      class="fixed right-0 top-0 bottom-0 w-full max-w-400 side-panel transition-all ease-in duration-150 pt-6 pb-4 px-4 rounded-l-10"
    >
      <Icon name="clear" class="absolute right-6 top-6 z-2 md:hidden w-5 h-5 cursor-pointer" @click="hidePanel" />
      <div class="absolute -top-[6px] right-17 w-0 h-0 border-l-[6px] border-l-transparent border-b-[8px] border-b-[#faf9ff] border-r-[6px] border-r-transparent"></div>
      <HelpWrapper v-if="tab === 'help'" @contact-us="tab = 'contact'" />
      <ContactForm v-if="tab === 'contact'" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import HelpWrapper from '@/components/help/HelpWrapper.vue';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import ContactForm from '@/components/help/ContactForm.vue';

export default {
  name: 'SidePanel',
  components: { ContactForm, FadeTransition, HelpWrapper },
  data() {
    return {
      tab: 'help',
    };
  },
  computed: {
    ...mapState(['showSidePanel']),
  },
  methods: {
    ...mapMutations(['SET_SHOW_SIDE_PANEL']),
    hidePanel() {
      this.SET_SHOW_SIDE_PANEL(false);
    },
  },
  watch: {
    showSidePanel() {
      this.tab = 'help';
    },
  },
};
</script>

<style scoped lang="scss">
.side-panel {
  //transform: translateX(100%);
}
</style>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-fade-in-enter-active {
  transition: all 0.1s ease;
  transition-delay: 0.2s;
}
.slide-fade-in-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-in-enter,
.slide-fade-in-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.slide-fade-top-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-top-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-top-enter,
.slide-fade-top-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
