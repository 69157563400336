<template>
  <input
    :type="type"
    :id="id ? id : null"
    :spellcheck="false"
    class="rounded-4 text-black text-13 leading-16 outline-none border border-solid p-2 h-9"
    :class="{ 'bg-gray-200 border-transparent cursor-default': disabled, 'border-gray-300': !disabled, 'cursor-default': readonly }"
    :value="modelValue"
    :disabled="disabled"
    :readonly="readonly"
    @input="updateValue($event.target.value)"
    @keyup="$emit('keyup', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    readonly: Boolean,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    id: [String],
  },
  emits: ['update:modelValue', 'keyup', 'blur'],
  compatConfig: { COMPONENT_V_MODEL: false },
  methods: {
    updateValue(value) {
      if (this.type === 'number') {
        this.$emit('update:modelValue', parseInt(value, 10));
      } else {
        this.$emit('update:modelValue', value);
      }
    },
  },
};
</script>
