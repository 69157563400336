<template>
  <Modal
    sheetbelowsm
    :title="project ? $t('projects.edit') : $t('projects.create')"
    :primary-button="$t('save')"
    :primary-button-disabled="!name.trim() || loading"
    :primary-button-loading="loading"
    :secondary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    close-button
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @close="onCancelClick"
  >
    <div class="w-full sm:w-700">
      <div class="w-full flex flex-col justify-start mt-3 py-7 px-10">
        <FormInput v-model="name" ref="name" :label="$t('projects.name')" :readonly="loading" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/ui/FormInput.vue';

export default {
  name: 'CreateProjectModal',
  components: { FormInput },
  data() {
    return {
      loading: false,
      name: '',
    };
  },
  mounted() {
    this.$refs.name.focus();
  },
  methods: {
    ...mapActions(['createProject']),
    async onSaveClick() {
      this.loading = true;
      await this.createProject({ name: this.name.trim(), tenants: {}, authentication: {}, cors: {} });
      this.loading = false;
      this.$emit('close');
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
